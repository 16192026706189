<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { initActions } from "./config/index";
import Watermark from "@/utils/Watermark";

export default {
  data() {
    return {};
  },
  created: function () {
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem(
        process.env.VUE_APP_CACHE_PERMISSION,
        JSON.stringify(this.permissions)
      );
    });
  },
  mounted() {
    if (this.accessToken !== "") {
      this.init();
      Watermark.set(this.userInfo.user_name);
    }
  },
  methods: {
    init() {
      let len = initActions.length;
      initActions.forEach((action) => {
        this.$store
          .dispatch(action.method, action.params)
          .then((response) => {
            len--;
            if (len === 0) {
              console.log("=========init success");
            }
          })
          .finally(() => {});
      });
    },
  },
  computed: {
    ...mapGetters({
      accessToken: "accessToken",
      permissions: "permissions",
    }),
  },
  watch: {
    accessToken(val, oldVal) {
      if (val !== "" && oldVal === "") {
        this.init();
      }
    },
  },
};
</script>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: left;
}
</style>
