/**
 * https://github.com/axios/axios
 */

import axios from 'axios'
import store from '../store'
import router from '../router'
import { Message } from 'element-ui'
import { getToken } from './AuthUtil'

let err401times = 0

function handleError(code, msg) {
  if (code === 401) {
    msg = '未登录或已失效，请重新登录.'
    err401times += 1
  }
  if (err401times > 1) {
    // console.log('==========err401times: ', err401times)
    setTimeout(() => { err401times = 0 }, 10000)
    return
  }
  if (store.getters.accessToken) {
    Message({
      message: msg,
      type: 'error',
      duration: 5 * 1000
    })
  }

  switch (code) {
    case 400:
    case 401:
      store.commit('LOGOUT')
      router.replace({
        path: process.env.VUE_APP_URL_LOGIN,
        query: { redirect: router.currentRoute.fullPath }
      })
      break
    default:
      break
  }
}

// 创建axios实例
const instance = axios.create({
  validateStatus: function (status) {
    return status >= 200 && status < 300
  },
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  timeout: 15000 // 请求超时时间
})

// request 拦截器
instance.interceptors.request.use(config => {
  let accessToken = ''
  if (store.getters.accessToken) {
    accessToken = store.getters.accessToken
  } else {
    accessToken = getToken()
  }
  if (accessToken) {
    config.headers['Authorization'] = 'Bearer ' + accessToken // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config
}, error => {
  // Do something with request error
  Promise.reject(error)
})

// response 拦截器
instance.interceptors.response.use(
  response => {
    if (response.data.success === false) {
      handleError(response.data.code, response.data.message)
    }
    if (response.data) {
      return response.data
    }
    return response
  },
  error => {
    // console.log('======error', error)
    let errMsg = error.message
    if (error.response.data.message !== undefined) {
      errMsg = error.response.data.message
    }
    handleError(error.response.status, errMsg)
    return Promise.reject(error)
  }
)

export default instance
