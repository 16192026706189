import Layout from '@/views/layout/index'
import Empty from '@/views/layout/empty'
import permCode from '@/config/permCode'

const sampleRouter = {
  path: '/sample',
  component: Layout,
  hidden: true,
  meta: {
    permission: permCode.SAMPLE_CODE
  },
  children: [{
    path: 'index',
    meta: {
      permission: permCode.SAMPLE_CODE
    },
    component: () => import('@/views/sample/index')
  }, {
    path: 'oss',
    meta: {
      permission: permCode.SAMPLE_CODE
    },
    component: () => import('@/views/sample/oss')
  }, {
    path: 'editor',
    meta: {
      permission: permCode.SAMPLE_CODE
    },
    component: () => import('@/views/sample/editor')
  }, {
    path: '/sample/1',
    meta: {
      permission: permCode.SAMPLE_CODE
    },
    component: Empty,
    // component: () => import('@/views/sample/1'),
    children: [{
      path: '/sample/1-1',
      meta: {
        permission: permCode.SAMPLE_CODE
      },
      component: () => import('@/views/sample/1-1')
    }, {
      path: '/sample/1-2',
      meta: {
        page: '1-2',
        permission: permCode.SAMPLE_CODE
      },
      component: () => import('@/views/sample/1-2')
    }, {
      path: '/sample/1-3',
      meta: {
        permission: permCode.SAMPLE_CODE
      },
      // component: () => import('@/views/sample/1-3')
      component: Empty,
      children: [{
        path: '/sample/1-4-1',
        meta: {
          permission: permCode.SAMPLE_CODE
        },
        component: () => import('@/views/sample/1-4-1')
      }]
    }, {
      path: '/sample/1-4',
      meta: {
        permission: permCode.SAMPLE_CODE
      },
      // component: () => import('@/views/sample/1-4')
      component: Empty,
      children: [{
        path: '/sample/1-4-1',
        meta: {
          permission: permCode.SAMPLE_CODE
        },
        component: () => import('@/views/sample/1-4-1')
      }]
    }, {
      path: '//www.baidu.com',
      meta: {
        permission: permCode.SAMPLE_CODE
      }
    }]
  }, {
    path: '2',
    meta: {
      permission: permCode.SAMPLE_CODE
    },
    component: () => import('@/views/sample/2')
  }, {
    path: '3',
    meta: {
      permission: permCode.SAMPLE_CODE
    },
    component: () => import('@/views/sample/3')
  }, {
    path: '4',
    meta: {
      permission: permCode.SAMPLE_CODE
    },
    component: () => import('@/views/sample/4')
  }]
}

export default sampleRouter
