import * as xuqiu from '../../api/xuqiu'
import * as customer from '../../api/customer'

// initial state
const state = {}

// getters
const getters = {}

// actions
const actions = {
  getXuqius ({ commit }, params) {
    return new Promise((resolve, reject) => {
      xuqiu.list(params).then(response => {
        response.items.forEach(function (item, idx) {
          response.items[idx]['content'] = item.content.replace(/\n/g, '<br>')
        })
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getMyPublishXuqius ({ commit }, params) {
    return new Promise((resolve, reject) => {
      xuqiu.myPublish(params).then(response => {
        response.items.forEach(function (item, idx) {
          response.items[idx]['content'] = item.content.replace(/\n/g, '<br>')
        })
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getWaitHandleXuqius ({ commit }, params) {
    return new Promise((resolve, reject) => {
      xuqiu.waitHandle(params).then(response => {
        let shopIds = {}
        response.items.forEach(function (item, idx) {
          shopIds[item.shop_id] = item.shop_id
          response.items[idx]['content'] = item.content.replace(/\n/g, '<br>')
        })
        // resolve(response)

        customer.suggestShop({
          ids: Object.keys(shopIds).join(','),
          fields: 'id,name,tech_ali_user',
          'per-page': Object.keys(shopIds).length
        }).then(shopResp => {
          let shopMap = []
          shopResp.items.forEach(function (item) {
            shopMap[item.id] = item
          })
          response.items.forEach(function (item, idx) {
            response.items[idx].tech_ali_user = shopMap[item.shop_id]['tech_ali_user']
          })
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      }).catch(error => {
        reject(error)
      })
    })
  },

  getMyHandleXuqius ({ commit }, params) {
    return new Promise((resolve, reject) => {
      xuqiu.myHandle(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  addXuqiu ({ commit }, data) {
    return new Promise((resolve, reject) => {
      xuqiu.add(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  editXuqiu ({ commit }, data) {
    return new Promise((resolve, reject) => {
      xuqiu.edit(data.id, data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getXuqiu ({ commit }, params) {
    return new Promise((resolve, reject) => {
      xuqiu.view(params.id, params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  handleXuqiu ({ commit }, data) {
    return new Promise((resolve, reject) => {
      xuqiu.handle(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getXuqiuLogs ({ commit }, params) {
    return new Promise((resolve, reject) => {
      xuqiu.listLog(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  addXuqiuLog ({ commit }, data) {
    return new Promise((resolve, reject) => {
      xuqiu.addLog(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  editXuqiuLog ({ commit }, data) {
    return new Promise((resolve, reject) => {
      xuqiu.editLog(data.id, data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getXuqiuTypesTree ({ commit }, params) {
    return new Promise((resolve, reject) => {
      xuqiu.treeType(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getXuqiuTypes ({ commit }, params) {
    return new Promise((resolve, reject) => {
      xuqiu.listType(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  addXuqiuType ({ commit }, data) {
    return new Promise((resolve, reject) => {
      xuqiu.addType(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  editXuqiuType ({ commit }, data) {
    return new Promise((resolve, reject) => {
      xuqiu.editType(data.id, data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  state,
  getters,
  actions
}
