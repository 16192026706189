<template>
  <el-table-column
    v-if="!col.children"
    :label="col.label"
    :type="col.type || ''"
    :prop="col.prop || ''"
    :width="col.width || ''"
    :min-width="col.minWidth || ''"
    :align="col.align || 'center'"
    :fixed="col.fixed || false"
    :show-overflow-tooltip="!col.els"
    :sortable="col.sortable ? 'custom' : false"
    :selectable="selectable"
  >
    <template #header>
      <slot v-if="col.headerSlot" :name="col.headerSlot" />
      <span v-else>{{ col.label }}</span>
    </template>
    <template #default="scope" v-if="!col.type">
      <slot
        v-if="col.contentSlot"
        :name="col.contentSlot"
        :rowData="scope.row"
        :rowIndex="scope.$index"
        :column="scope.column"
      />
      <div
        v-else-if="!col.contentType"
        :style="{ textAlign: col.textAlign ? col.textAlign : 'left' }"
        :class="!col.els ? 'nowarp' : ''"
      >
        {{ scope.row[col.prop] }}
      </div>
      <div
        v-else-if="col.contentType"
        :style="{ textAlign: col.textAlign ? col.textAlign : 'left' }"
        :class="!col.els ? 'nowarp' : ''"
        v-html="
          getValue(
            scope.row,
            col.prop,
            col.contentType,
            col.enumName,
            col.format
          )
        "
      ></div>
    </template>
  </el-table-column>
  <el-table-column
    v-else
    :label="col.label"
    :width="col.width || ''"
    :min-width="col.minWidth || ''"
    :fixed="col.fixed || false"
    :align="col.align || 'center'"
    :show-overflow-tooltip="!col.els"
  >
    <TableColumn v-for="t in col.children" :key="t.prop + Date.now()" :col="t">
      <template v-for="slot in Object.keys($scopedSlots)" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </TableColumn>
  </el-table-column>
</template>

<script>
import { toThousands2, toPercentage } from '@/utils/Number'
import dayjs from 'dayjs'
export default {
  name: 'TableColumn',
  props: {
    col: {
      type: Object,
      default: () => {
        return {}
      }
    },
    selectable: Function
  },
  methods: {
    getValue(row, prop, contentType, enumName, format) {
      //格式化单元格展示内容
      let value = null
      let list = prop.split('.')
      if (list.length == 1) {
        value = row[prop]
      } else {
        for (let i = 0; i < list.length; i++) {
          if (i == 0) {
            value = row?.[list[i]]
          } else {
            value = value?.[list[i]]
          }
          if (!value) break
        }
      }

      if (typeof value != 'number' && typeof value != 'boolean' && !value) {
        value = '-'
      } else if (contentType) {
        switch (contentType) {
          case 'enum': //取整、千分位
            value = this.enumOptionName(enumName, value)
            break
          case 'date': //取整、千分位
            value = dayjs(value).format(format)
            break
          case 'person': //取整、千分位
            value = toThousands2(value)
            break
          case '%': //取整、千分位
            value = toPercentage(value)
            break
          case 'id': //获取id对应的文本
            value = toPercentage(value)
            break
        }
      }
      return value
    }
  }
}
</script>

<style scoped>
.nowarp {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>