<template>
  <el-submenu v-if="item.children !== undefined && item.children.length > 0" :index="item.url" :key="item.url" v-permission="item.permission">
    <template slot="title">
      <i v-if="showIcon && item.icon" :class="item.icon"/><span slot="title">{{ item.title }}</span>
    </template>
    <menu-item v-for="subItem in item.children" :key="subItem.url" :item="subItem"/>
  </el-submenu>
  <el-menu-item v-else-if="isHref(item.url)" :key="item.url" v-permission="item.permission">
    <i v-if="showIcon && item.icon" :class="item.icon"/>
    <a :href="item.url" :target="item.target || '_blank'" style="color:#fff; display: inline-block;"><span slot="title">{{ item.title }}</span></a>
  </el-menu-item>
  <el-menu-item v-else :index="item.url" :key="item.url" v-permission="item.permission">
    <i v-if="showIcon && item.icon" :class="item.icon"/>
    <span slot="title">{{ item.title }}</span>
  </el-menu-item>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    showIcon: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  methods: {
    isHref (url) {
      // console.log(url.indexOf('http') === 0 || url.indexOf('//') === 0, ' : ', url)
      return url.indexOf('http') === 0 || url.indexOf('//') === 0
    }
  }
}
</script>
