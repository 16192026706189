import Layout from "@/views/layout/index";
import permCode from "@/config/permCode";

const qaRouter = {
  path: "/qa",
  component: Layout,
  hidden: true,
  meta: {
    permission: [permCode.QA_VIEW, permCode.CATE_VIEW],
  },
  children: [
    {
      path: "index",
      meta: {
        permission: permCode.QA_VIEW,
        title: "业务FAQ库",
      },
      component: () => import("@/views/qa/list/index.vue"),
    },
    {
      path: "cate",
      meta: {
        permission: permCode.CATE_VIEW,
        title: "业务FAQ库分类",
      },
      component: () => import("@/views/qa/cate/index.vue"),
    },
  ],
};

export default qaRouter;
