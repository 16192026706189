import Vue from "vue";
import router from "@/router";
import { addEventTracking } from "@/api/qa";

function formatBinding(binding) {
  const { value, modifiers } = binding;
  let params = {
    event: "click",
    data: {
      serviceId: process.env.VUE_APP_QA_TERM_CODE,
      pageName: router.currentRoute.path,
      moduleName: value.moduleName ?? router.currentRoute.meta.title,
      actionName: value.actionName,
    },
  };
  return params;
}

Vue.directive("track", {
  bind: function (el, binding) {
    const format = formatBinding(binding);
    el.data = format.data;
    el.addEventListener(
      format.event,
      function (e) {
        addEventTracking(el.data);
      },
      false
    );
  },
  update: function (el, binding) {
    const format = formatBinding(binding);
    el.data = format.data;
  },
});
