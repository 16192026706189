<template>
  <el-header style="z-index: 1;">
    <span>
      <el-link
        href="/"
        :underline="false"
        style="color:#fff"
      >
        {{ $t('app.name') }}
      </el-link>
    </span>

    <el-dropdown
      class="header-operations"
      @command="handleCommand"
      style="padding-right: 0px;"
    >
      <span class="el-dropdown-link">
        {{ userInfo.user_name }}<i class="el-icon-arrow-down" />
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="modify-password">
          修改密码
        </el-dropdown-item>
        <el-dropdown-item command="logout">
          退出
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-menu
      class="el-menu-demo header-operations"
      mode="horizontal"
      background-color="#373d41"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <menu-item
        :show-icon="false"
        v-for="item in menuItems"
        :key="item.url"
        :item="item"
      />
    </el-menu>

<!--    <el-dialog-->
<!--      title="修改密码"-->
<!--      :visible.sync="passwordDialogVisible"-->
<!--      width="50%"-->
<!--    >-->
<!--      <modify-password @handle-modify-password="handleModifyPassword" />-->
<!--    </el-dialog>-->

    <el-drawer
      title="修改密码"
      size="40%"
      :append-to-body="true"
      :visible.sync="passwordDialogVisible"
      :direction="direction">
      <modify-password @handleModifyPassword="handleModifyPassword" style="margin: 80px 10px 10px;"/>
    </el-drawer>
  </el-header>
</template>

<script>
import { mapGetters } from "vuex";
import MenuItem from "./MenuItem";
import ModifyPassword from "../../auth/components/ModifyPassword";
import authService from "../../../services/auth";
export default {
  name: "XHeader",
  components: { ModifyPassword, MenuItem },
  data() {
    return {
      menuItems: [],
      authUrl: process.env.VUE_APP_URL_LOGIN,
      // menuItems: [],
      passwordDialogVisible: false,
      drawerTitle: '修改密码',
      direction: 'rtl'
    };
  },
  mounted() {
    this.initMenuItems();
  },
  methods: {
    initMenuItems() {
      // console.log("======navItem", this.menuItems);

      authService
        .getNavItems()
        .then((resp) => {
          // console.log("==========", resp);
          this.menuItems = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleCommand: function (command) {
      if (command === "logout") {
        this.logout();
      } else if (command === "modify-password") {
        this.passwordDialogVisible = true
        // this.$router.push({
        //   path: "/auth/modify-password",
        // });
      }
    },
    logout: function () {
      let authUrl = this.authUrl;
      this.$store
        .dispatch("logout")
        .then((response) => {
          this.$router.push({ path: authUrl });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.dispatch("setMenuItems", []);
        });
    },
    handleModifyPassword: function (msg) {
      this.passwordDialogVisible = false
    },
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
      // menuItems: 'navMenu'
    }),
  },
};
</script>

<style>
.header-logo {
  display: inline-block;
  vertical-align: middle;
}

.header-operations {
  display: inline-block;
  float: right;
  padding-right: 30px;
  height: 100%;
}

.header-operations li {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  padding: 0 10px;
  /*margin: 0 10px;*/
  line-height: 60px;
  cursor: pointer;
}

.header-operations .el-submenu__title {
  padding: 0px;
}

.header-operations .el-dropdown-link {
  color: #fff;
}
</style>
