import permCode from "@/config/permCode";

export default [
  {
    permission: permCode.MESSAGE_VIEW,
    sortOrder: 80,
    title: "消息通知",
    icon: "el-icon-location",
    url: "/message/type",
    children: [
      {
        permission: permCode.MESSAGE_TYPE,
        sortOrder: 1,
        title: "消息类型",
        icon: "el-icon-location",
        url: "/message/type",
      },
      {
        permission: permCode.MESSAGE_OPERATE,
        sortOrder: 2,
        title: "消息发布",
        icon: "el-icon-location",
        url: "/message/create",
      },
      {
        permission: permCode.MESSAGE_VIEW,
        sortOrder: 3,
        title: "我的消息",
        icon: "el-icon-location",
        url: "/message/my",
      },
    ],
  },
];
