import { loadModuleFiles, compare } from '@/utils/ArrayUtil'
import { buildMenuItemPermission } from '@/utils/AuthUtil'

const modulesFiles = require.context('./menus', false, /\.js$/)
const modules = loadModuleFiles(modulesFiles)
// const menuItems = Object.values(modules)
const menuItems = Object.values(modules).map((item) => {
  if (item.sortOrder === undefined) {
    item.sortOrder = 100
  }
  return item
}).sort(compare('sortOrder'))
buildMenuItemPermission(menuItems)
export default menuItems
