import request from "../utils/request";

import { AUTH as authUrl } from "./constant";

export function loginByDingtalk(data) {
  return request({
    url: authUrl.AUTH_LOGIN,
    method: "POST",
    data: data,
  });
}

export function login(username, password) {
  return request({
    url: authUrl.AUTH_LOGIN,
    method: "POST",
    data: {
      username,
      password,
    },
  });
}

export function logout() {
  return request({
    url: authUrl.AUTH_LOGOUT,
    method: "POST",
  });
}

export function getPermission(params) {
  return request({
    url: authUrl.AUTH_PERMISSION,
    method: "GET",
    params: params,
  });
}

export function getNavItems(params = {}) {
  return request({
    url: authUrl.AUTH_SITES,
    method: "GET",
    params: params,
  });
}

export function modifyPassword(data) {
  return request({
    url: authUrl.MODIFY_PASSWORD,
    method: "POST",
    data: data,
  });
}

export function suggest(params) {
  return request({
    url: authUrl.ADMIN_SUGGEST,
    method: "GET",
    params: params,
  });
}

export function listDept(params) {
  return request({
    url: authUrl.DEPT_LIST,
    method: "GET",
    params: params,
  });
}
