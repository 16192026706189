import { isEmpty } from "@/utils/ValidateUtil";

export function add(array, key, value) {
  if (get(array, key) === null) {
    set(array, key, value);
  }
  return array;
}

export function get(array, key, defaultValue = null) {
  if (key === null) {
    return array;
  }

  if (key.indexOf(".") === -1) {
    return array[key] || defaultValue;
  }
  let segments = key.split(".");
  for (let i = 0; i < segments.length; i++) {
    if (exists(array, segments[i])) {
      array = array[segments[i]];
    } else {
      return defaultValue;
    }
  }
  return array;
}

export function set(array, key, value) {
  let _keys = key.split(".");
  let _leafKey = _keys.pop();
  let _obj = array;
  _keys.forEach(function (_key) {
    if (_obj[_key] === undefined) {
      _obj[_key] = {};
    }
    _obj = _obj[_key];
  });
  _obj[_leafKey] = value;

  return array;
}

export function has(array, keys) {
  if (!Array.isArray(keys)) {
    keys = [keys];
  }
  if (!array || keys.length === 0) {
    return false;
  }

  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    if (exists(array, key)) {
      continue;
    }
    let subKeyArray = array;
    let segments = key.split(".");
    for (let j = 0; j < segments.length; j++) {
      if (exists(subKeyArray, segments[j])) {
        subKeyArray = subKeyArray[segments[j]];
      } else {
        return false;
      }
    }
  }
  return true;
}

export function hasAny(array, keys) {
  if (!array || keys === null || keys.length === 0) {
    return false;
  }
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    if (has(array, key)) {
      return true;
    }
  }
  return false;
}

export function exists(array, key) {
  return array[key] !== undefined;
}

export function dot(array, prepend = "") {
  let results = {};
  Object.keys(array).forEach((key) => {
    let value = array[key];
    if (
      (Array.isArray(value) && value.length !== 0) ||
      (typeof value === "object" && Object.keys(value).length > 0)
    ) {
      Object.assign(results, dot(value, prepend + key + "."));
    } else {
      results[prepend + key] = value;
    }
  });
  return results;
}

export function shuffle(array) {
  return array.slice().sort(() => Math.random() - 0.5);
}

export function loadModuleFiles(modulesFiles) {
  return modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
    const value = modulesFiles(modulePath);
    if (Array.isArray(value.default)) {
      value.default.forEach(function (item, idx) {
        modules[moduleName + idx] = item;
      });
    } else {
      modules[moduleName] = value.default;
    }
    return modules;
  }, {});
}

export function fromObj(obj, props = null) {
  if (Array.isArray(props)) {
    let result = [];
    props.forEach((prop) => {
      result.push(obj[prop]);
    });
    return result;
  }
  return Object.values(obj);
}

export function toCsv(array, colSep = ",", rowSep = "\r\n") {
  let result = [];
  array.forEach((row) => {
    if (Array.isArray(row)) {
      result.push(row.join(colSep));
    } else {
      result.push(row);
    }
  });
  return result.join(rowSep);
}

export function toTree(
  array,
  rootId = 0,
  itemKey = "id",
  parentKey = "parent_id",
  childrenKey = "children"
) {
  let tree = [];
  let topLevelItems = [];
  let childrenItems = {};
  array.forEach((item) => {
    let parentId = item[parentKey];
    // delete item[parentKey]
    if (rootId === parentId) {
      topLevelItems.push(Object.assign({}, item));
    } else {
      if (childrenItems[parentId] === undefined) {
        childrenItems[parentId] = [];
      }
      childrenItems[parentId].push(Object.assign({}, item));
    }
  });

  topLevelItems.forEach((item) => {
    item = _buildSubTree(item, childrenItems, itemKey, childrenKey);
    tree.push(item);
  });

  return tree;
}

export function _buildSubTree(
  item,
  childrenItems,
  itemKey,
  childrenKey,
  level = 2
) {
  if (Array.isArray(childrenItems[item[itemKey]])) {
    item[childrenKey] = [];
    childrenItems[item[itemKey]].forEach((childItem) => {
      item[childrenKey].push(
        _buildSubTree(childItem, childrenItems, itemKey, childrenKey, level + 1)
      );
    });
  } else {
    // item[childrenKey] = []
  }
  return item;
}

export function toMap(array, mapKey, childrenKey = "children") {
  const isMap = array.every((item) => {
    return !isEmpty(item[mapKey]);
  });
  const result = isMap ? {} : [];
  array.forEach((item) => {
    if (isMap) {
      if (Array.isArray(item[childrenKey])) {
        item[childrenKey] = toMap(item[childrenKey], mapKey, childrenKey);
      }
      result[item[mapKey]] = item;
    } else {
      result.push(Object.assign({}, item));
    }
  });

  return result;
}

export function toTreeOptions(
  data,
  name = "name",
  child = "children",
  indent = "\u3000"
) {
  return _buildTreeOptions(data, name, child, indent, 0);
}

function _buildTreeOptions(data, name, child, indent, level = 1) {
  let result = [];
  data.forEach(function (item) {
    let children = item[child];
    delete item["children"];
    item[name] = new Array(level + 1).join(indent) + item[name];
    result.push(item);
    if (children && children.length) {
      result = result.concat(
        _buildTreeOptions(children, name, child, indent, level + 1)
      );
    }
  });
  return result;
}

export function toCascaderOptions(
  data,
  valueField = "id",
  labelField = "name",
  childField = "children"
) {
  let result = [];
  return _buildCascaderOptions(result, data, valueField, labelField);
}

function _buildCascaderOptions(result, data, valueField, labelField) {
  data.forEach(function (item) {
    let row = {
      value: item[valueField],
      label: item[labelField],
    };
    if (item.children !== undefined && item.children.length > 0) {
      row.children = _buildCascaderOptions(
        [],
        item["children"],
        valueField,
        labelField
      );
    }
    result.push(row);
  });
  return result;
}

/**
 * 触点,搜索,搜索\r\n,付费广告,钻石展位\r\n,,品牌专区\r\n,,明星店铺\r\n消费者属性,月均消费金额,2000-2999元\r\n,,3000-5999元\r\n,兴趣偏好,家庭主妇\r\n,,买鞋控\r\n,,美丽教主
 * ["触点","搜索","搜索"],["","付费广告","钻石展位"],["","","品牌专区"],["","","明星店铺"],["消费者属性","月均消费金额","2000-2999元"],["","","3000-5999元"],["","兴趣偏好","家庭主妇"],["","","买鞋控"],["","","美丽教主"]
 * @param value  array | string
 * @param rowSep
 * @param colSep
 * @returns {[]}
 */
export function csvToTree(value, rowSep = "\r\n", colSep = ",") {
  let result = [];
  let rows = Array.isArray(value) ? value : value.split(rowSep);
  rows.forEach((row) => {
    let cols = Array.isArray(row) ? row : row.split(colSep);
    let target = result;
    cols.forEach((col, colIdx) => {
      if (col !== "") {
        const isLastCol = colIdx === cols.length - 1;
        let item = { name: col };
        if (!isLastCol) {
          item.children = [];
        }
        target.push(item);
        if (!isLastCol) {
          target = item.children;
        }
      } else {
        target = target[target.length - 1].children;
      }
    });
  });

  return result;
}

/**
 * [{"name":"触点","children":[{"name":"搜索","children":[{"name":"搜索"}]},{"name":"付费广告","children":[{"name":"钻石展位"},{"name":"品牌专区"},{"name":"明星店铺"}]}]},{"name":"消费者属性","children":[{"name":"月均消费金额","children":[{"name":"2000-2999元"},{"name":"3000-5999元"}]},{"name":"兴趣偏好","children":[{"name":"家庭主妇"},{"name":"买鞋控"},{"name":"美丽教主"}]}]}]
 * @param array
 * @returns {[]}
 */
export function treeToCsv(array) {
  let result = [];
  _treeToCsv(result, array);
  return result;
}

function _treeToCsv(result, array, path = [], level = 1) {
  array.forEach((item, idx) => {
    if (idx === 0) {
      if (result.length > 0) {
        result[result.length - 1].push(item.name);
      } else {
        result.push(["", item.name]);
      }
    } else {
      let row = [];
      for (let i = 0; i < level; i++) {
        row.push("");
      }
      row.push(item.name);
      result.push(row);
    }
    if (Array.isArray(item.children) && item.children.length > 0) {
      _treeToCsv(result, item.children, [].concat(path, item.name), level + 1);
    } else {
      result[result.length - 1][0] = [].concat(path, item.name);
    }
  });
}

export function compare(prop, desc = false) {
  return function (obj1, obj2) {
    let result = 0;
    let val1 = obj1[prop];
    let val2 = obj2[prop];
    if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
      val1 = Number(val1);
      val2 = Number(val2);
    }
    if (val1 < val2) {
      result = -1;
    } else if (val1 > val2) {
      result = 1;
    }
    return desc ? 0 - result : result;
  };
}

export const treeFindPath = function (
  tree,
  childNodes,
  field,
  value,
  path = []
) {
  if (!tree) return [];
  for (const data of tree) {
    path.push(data);
    if (data[field] === value) return path;
    if (data[childNodes]) {
      const findChildren = treeFindPath(
        data[childNodes],
        childNodes,
        field,
        value,
        path
      );
      if (findChildren.length) return findChildren;
    }
    path.pop();
  }
  return [];
};
