export default {
  app: {
    name: '火奴管理系统-NEW',
    dashboard: '首页'
  },
  navbar: {
    logOut: '退出登录',
    dashboard: '首页',
    github: '项目地址',
    screenfull: '全屏',
    theme: '换肤'
  },
  login: {
    title: '系统登录',
    logIn: '登录',
    username: '账号',
    password: '密码'
  },
  customer: {
    shop_name: '店铺名称',
    cat_name: '所属类目',
    customer_manager: '客户经理',
    operation_group_name: '运营组',
    operation_group_uid: '运营组ID',
    operation_manager: '运营人员',
    operation_manager_uid: '运营人员ID',
    sales_manager: '销售经理',
    sales_manager_uid: '销售经理ID',
    tech_manager: '技术人员',
    tech_manager_uid: '技术人员ID',
    service_start_date: '接入日期',
    coop_target: '合作对象',
    platform: '平台',
    remark: '备注',
    status: '状态',
    tech_cycle_update: '数据是否更新',
    ali_datafactory_enabled: '开通数据工厂',
    ali_datastrategy_enabled: '开通策略中心'
  },
  adminStatus: {
    NORMAL: '正常',
    LOCKED: '锁定'
  },
  customerStatus: {
    PENDING: '待合作',
    NORMAL: '合作中'
  },
  customerLevel: {
    NO_LEVEL: '无价值',
    LOW_VALUE: '低价值',
    COMMON: '普通',
    IMPORTANCE: '重要'
  },
  shopPlatform: {
    JINGDONG: '京东电商',
    ALIBABA: '阿里电商',
    ALI_CHAOTUI: '超级推荐',
    ALI_NEW_RETAIL: '阿里新零售',
    OCEANENGINE: '巨量引擎'
  },
  shopStatus: {
    'PENDING': '待合作',
    'NORMAL': '合作中',
    'FINISH': '合作结束',
    'ABORT': '合作终止'
  },
  coopTarget: {
    'BRAND': '品牌',
    'TP': 'TP',
    'BRAND_TP': '品牌/TP'
  },
  xuqiu: {
    attachments: '附件',
    content: '内容',
    end_time: '完成时间',
    from_admin_name: '提出者',
    finish_time: '结束时间',
    handle_admin_name: '处理人',
    handle_group: '处理组',
    name: '名称',
    priority: '优先级',
    remark: '备注',
    shop_name: '店铺名称',
    shop_status: '店铺状态',
    start_time: '开始时间',
    sub_type_name: '需求类型',
    to_handle_admin: '指派给人',
    to_handle_group: '指派给组'
  },
  examQuestionType: {
    SINGLE: '单选题',
    MULTIPLE: '不定项',
    BOOLEAN: '判断题'
  },
  dateType: {
    RELATIVE_RANGE: '相对日期',
    ABSOLUTE_DATE_RANGE: '固定日期',
    APPOINTED_DATE: '固定日期'
  },
  dayFrequency: {
    OPEN_OPEN: '不限',
    OPEN_CLOSE: '大于',
    CLOSE_CLOSE: '区间'
  },
  enum: {
    PENDING: '待合作',
    NORMAL: '正常',
    JINGDONG: '京东',
    ALIBABA: '阿里'
  }
}
