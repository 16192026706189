import permCode from "@/config/permCode";

export default [
  {
    permission: permCode.DY_AD_ACCESS_TOKEN_LIST,
    sortOrder: 120,
    title: "抖音广告",
    icon: "el-icon-location",
    url: "/dy-ad/",
    children: [
      {
        permission: permCode.DY_AD_ACCESS_TOKEN_LIST,
        sortOrder: 1,
        title: "账户列表",
        icon: "el-icon-location",
        url: "/dy-ad/access-token",
      }
    ],
  },
];
