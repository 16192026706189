export const SSH_PUBKEY =
  "-----BEGIN PUBLIC KEY-----\n" +
  "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA16HJU0lNaHZXxH84oO1E\n" +
  "uUoOPxfzOlLyrihsDo69gaRIik5GFj/SPl02bksK7YpCdiqfQvEyjfyndiI7tfaF\n" +
  "N7aFLjYGfoXK1msMDVybcn/3A3G/9fQ5nFi+sNsyUB9tvyvEEkM1IrXaLgMvL5MB\n" +
  "KNbNmHzaRh25RxiooiXz2E1FlTX+2uhBjX/FOEooIrarXeWUwMT3icjtI1zqRtfE\n" +
  "YGC5N5uu9yu9IZZE+wE9qxIffkjMG014ncxJ3hRbygiU8EP7Tk7wWdP/QkJ7XJcV\n" +
  "cm9gXGetgiR1V1WbTuAn1FtYi7nAlML49aBA59OkLVeyw0OHdije55b5Gy1LQoiJ\n" +
  "nQIDAQAB\n" +
  "-----END PUBLIC KEY-----";

export const initActions = [
  {
    method: "fetchPermission",
    params: {},
  },
  // {
  //   method: "getNavMenu",
  //   params: {},
  // },
  {
    method: "fetchTerm",
    params: {
      termCode: process.env.VUE_APP_QA_TERM_CODE,
    },
  },
];

export default {
  SSH_PUBKEY: SSH_PUBKEY,
  initActions: initActions,
};
