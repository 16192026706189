const getters = {
  sidebar: (state) => state.app.sidebar,
  language: (state) => state.app.language,
  device: (state) => state.app.device,
  menuItems: (state) => state.auth.menuItems,
  navMenu: (state) => state.base.navMenu,
  accessToken: (state) => state.auth.accessToken,
  userInfo: (state) => state.auth.userInfo,
  dingtalkLogin: (state) => !!state.auth.userInfo.is_dingtalk,
  permissions: (state) => state.auth.permissions,
  qaTerm: (state) => state.qa.term,
  suggest: (state) => state.auth.suggest,
  deptOptions: (state) => state.auth.deptOptions,
  deptTreeOptions: (state) => state.auth.deptTreeOptions,
  messageTypeList: (state) => state.auth.messageTypeList,
  message: (state) => state.auth.message,
};
export default getters;
