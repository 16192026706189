import * as authApi from '../api/auth'

const navItem = {
  fields: {
    serviceId: { label: 'serviceId' },
    permission: {
      label: '权限',
      value: (item, prop) => {
        return '@'
      }
    },
    title: {
      label: '名称',
      value: (item, prop) => {
        return item.serviceName
      }
    },
    url: {
      label: 'URL',
      value: (item, prop) => {
        return item.websiteUrl || ''
      }
    }
  },
  formatItem (item) {
    for (const prop in this.fields) {
      if (typeof this.fields[prop]['value'] === 'function') {
        item[prop] = this.fields[prop]['value'](item, prop)
      }
    }
    return item
  }
}

export default {
  getNavItems: (params) => {
    return new Promise((resolve, reject) => {
      authApi.getNavItems(params).then(response => {
        if (Array.isArray(response.data)) {
          const navItems = []
          response.data.forEach((item, idx) => {
            const _item = navItem.formatItem(item)
            if (_item.url !== '') {
              navItems.push(navItem.formatItem(item))
            }
          })
          response.data = navItems
        }
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
