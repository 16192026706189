<template>
  <div class="messageAlert">
    <div
      class="item"
      v-for="(item, index) in noticeList"
      :key="item.id"
      @click="toMessage(item, index)"
    >
      <div class="flex">
        <h2 class="header">公告：{{ item.title }}</h2>
        <div class="headerRight">
          <el-button
            class="btn"
            type="primary"
            :loading="loading"
            @click.stop="read(item, index)"
            v-if="item.isReaded === false"
            >标为已读</el-button
          >
          <i class="el-icon-close close" @click="close(index)"></i>
        </div>
      </div>

      <div class="content">
        <div>
          #&nbsp;{{ item.noticeTypeName }}&nbsp;#&nbsp;&nbsp;&nbsp;&nbsp;{{
            item.createUserName
          }}&nbsp;&nbsp;&nbsp;&nbsp;{{ item.createTime }}
        </div>
        <!-- <div>发布类型：{{ item.noticeTypeName }}</div>
        <div>发布时间：{{ item.createTime }}</div>
        <div>发布内容：</div> -->
        <div class="detail" v-html="item.noticeContent"></div>
        <div v-if="item.files.length > 0">
          <div>附件：</div>
          <div class="urls">
            <div class="url" v-for="item2 in item.files" :key="item2.url">
              <a @click.stop download :title="item2.name" :href="item2.url">{{
                item2.name
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <messageContentDialog
      :noticeTypeList="noticeTypeList"
      ref="messageContentDialog"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { messageList, readNotice } from '@/api/message'
import messageContentDialog from '@/components/messageContentDialog'
export default {
  components: { messageContentDialog },
  data() {
    return {
      noticeTypeList: [], // 通知类型列表
      noticeList: [], // 通知列表
      loading: false
    }
  },
  async created() {
    await this.getNoticeTypeList()
  },
  mounted() {
    this.getMessage()
  },
  methods: {
    getMessage() {
      // 获取通知列表
      let params = {
        startDate: dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
        isReaded: false,
        page_no: 1,
        page_size: 10000
      }
      messageList(params).then((res) => {
        let list = res?.data?.records || []
        this.noticeList = list.map((item) => {
          return {
            ...item,
            createTime: dayjs(item.createTime).format('YYYY-MM-DD HH:MM'),
            files: item.files ? JSON.parse(item.files) : [],
            noticeTypeName: this.noticeTypeList.find(
              (noticeType) => noticeType.id === item.noticeTypeId
            )?.name
          }
        })
      })
    },
    async getNoticeTypeList() {
      // 获取所有通知类型列表
      if (
        this.$store.getters.messageTypeList &&
        this.$store.getters.messageTypeList.length > 0
      ) {
        this.noticeTypeList = this.$store.getters.messageTypeList
      } else {
        let res = await this.$store.dispatch('listAdminMessageTypes', {
          page: -1
        })
        if (res) {
          this.noticeTypeList = res?.data?.records || []
        }
      }
    },
    close(index) {
      // 关闭弹窗
      this.noticeList.splice(index, 1)
    },
    toMessage(item, index) {
      // 点击通知打开通知详情弹窗
      this.$refs.messageContentDialog.init({ item: item, type: 'read2' })
      if (!this.noticeList[index].isReaded) {
        readNotice([item.id]).then((res) => {
          if (res && res.data && res.success) {
            this.noticeList[index].isReaded = true
            this.$message.success('自动已读成功')
          } else {
            this.$message.warning('自动已读失败！')
          }
        })
      }
    },
    read(item, index) {
      // 已读按钮
      this.loading = true
      readNotice([item.id])
        .then((res) => {
          if (res && res.data && res.success) {
            this.close(index)
            this.$message.success('标记成功')
          } else {
            this.$message.warning(res?.message || '操作失败')
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.messageAlert {
  position: fixed;
  top: 80px;
  right: 20px;
  width: 30%;
  min-width: 360px;
  z-index: 9999;
}
.item {
  position: relative;
  cursor: pointer;
  width: 100%;
  background: #c6e2ff;
  padding: 14px 26px 14px 13px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}
.flex {
  display: flex;
  justify-content: space-between;
}

.close {
  cursor: pointer;
  color: #909399;
  font-size: 16px;
  margin-left: 12px;
}

.header {
  font-weight: 700;
  font-size: 16px;
  color: #303133;
  margin: 0 0 8px 0;
}

.content {
  font-size: inherit;
  line-height: 1.8;
  color: #606266;
  text-align: justify;
}
.detail {
  max-height: 300px;
  overflow: auto;
  padding: 0 24px;
  box-sizing: border-box;
}
.urls {
  padding: 0 24px;
  box-sizing: border-box;
}
.url {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

/*垂直滚动条样式宽度*/
::-webkit-scrollbar {
  width: 4px;
}
/*背景色*/
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
/*滑块颜色*/
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}
</style>>
