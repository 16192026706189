import request from "../utils/request";

import { MESSAGE as messageUrl } from "./constant";

export function noticeTypeList(data) {
  //消息类型列表（所有）
  return request({
    url: messageUrl.NOTICE_TYPE_LIST,
    method: "POST",
    data: data,
  });
}

export function createNoticeType(data) {
  //新增或编辑消息
  return request({
    url: messageUrl.NOTICE_TYPE_CREATE,
    method: "POST",
    data: data,
  });
}

export function delNoticeType(id) {
  //删除消息或通知
  return request({
    url: messageUrl.NOTICE_TYPE_DELETE + `?id=${id}`,
    method: "POST",
  });
}

export function noticeTypeMYList(data) {
  //消息类型列表（我有权限能使用的）
  return request({
    url: messageUrl.NOTICE_TYPE_MY_LIST,
    method: "POST",
    data: data,
  });
}

export function noticeTypeCountList(data) {
  //消息类型数据统计列表
  return request({
    url: messageUrl.NOTICE_TYPE_COUNT_LIST,
    method: "POST",
    data: data,
  });
}

export function createNotice(data) {
  //新增或编辑消息
  return request({
    url: messageUrl.NOTICE_CREATE,
    method: "POST",
    data: data,
  });
}

export function noticeList(data) {
  //通知列表(我创建的通知)
  return request({
    url: messageUrl.NOTICE_LIST,
    method: "POST",
    data: data,
  });
}

export function messageList(data) {
  //消息列表(我收到的通知)
  return request({
    url: messageUrl.MESSAGE_LIST,
    method: "POST",
    data: data,
  });
}

export function delNotice(id) {
  //删除消息或通知
  return request({
    url: messageUrl.NOTICE_DELETE + `?msgId=${id}`,
    method: "POST",
  });
}

export function readNotice(data) {
  //标为已读
  return request({
    url: messageUrl.NOTICE_READ,
    method: "POST",
    data: data,
  });
}

export function getNoticeDetail(data) {
  //获取消息详情
  return request({
    url: messageUrl.NOTICE_DETAIL,
    method: "POST",
    data: data,
  });
}
