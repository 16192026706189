import permCode from "@/config/permCode";

export default [
  {
    permission: '@',
    title: "Chat-GPT",
    sortOrder: 1,
    icon: "el-icon-menu",
    url: "https://gpt.console.huonu.com/",
  },
  {
    permission: [permCode.QA_VIEW, permCode.CATE_VIEW],
    title: "业务FAQ库",
    sortOrder: 2,
    icon: "el-icon-menu",
    url: "/qa/index",
  },
  {
    permission: '@',
    title: "我的问卷",
    sortOrder: 1,
    icon: "el-icon-menu",
    url: "https://crm2.console.huonu.com/#/survey/my-survey",
  },
  {
    permission: '@',
    title: "宝贝评论-内测",
    sortOrder: 3,
    icon: "el-icon-menu",
    url: "http://rpa.huonu.local/",
  }
];
