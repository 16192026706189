import cacheUtil from '@/utils/CacheUtil'

const USER_INFO = 'userInfo'
const ACCESS_TOKEN = 'accessToken' // Token键值
const SKIP_AUTH = process.env.VUE_APP_SKIP_AUTH || false

export function getUserInfo () {
  return cacheUtil.getCookie(USER_INFO)
}

export function setUserInfo (val, attributes = {}) {
  attributes['domain'] = true
  return cacheUtil.setCookie(USER_INFO, val, attributes)
}

export function removeUserInfo () {
  return cacheUtil.removeCookie(USER_INFO, { domain: true })
}

// token
export function getToken () {
  let token = cacheUtil.getCookie(ACCESS_TOKEN)
  return token === undefined ? null : token
}

export function setToken (token, attributes = {}) {
  attributes['domain'] = true
  return cacheUtil.setCookie(ACCESS_TOKEN, token, attributes)
}

export function removeToken () {
  return cacheUtil.removeCookie(ACCESS_TOKEN, { domain: true })
}

export function hasToken () {
  const token = getToken()
  return token !== null
}

export function formatPermission (data) {
  const result = []
  for (const app in data) {
    data[app].forEach(item => {
      result.push([app, item].join('.'))
    })
  }
  return result
}

export function hasPermission (permission, assignedPermissions) {
  if (SKIP_AUTH === 'true') {
    return true
  }
  if (typeof permission === 'string') {
    if (permission === '*' || (permission === '@' && hasToken())) {
      return true
    }
    permission = [permission]
  }

  return assignedPermissions.some(assigned => {
    if (assigned === '*') {
      return true
    }
    return permission.some(item => {
      return assigned === item || (item === '@' && hasToken())
      // let pos = item.indexOf('.*')
      // if (pos > 0) {
      //   return assigned.indexOf(item.substr(0, pos + 1)) === 0
      // } else if ((pos = assigned.indexOf('.*')) > 0) {
      //   return item.indexOf(assigned.substr(0, pos + 1)) === 0
      // } else {
      //   return assigned === item
      // }
    })
  })
}

export function buildMenuItemPermission (menuItems) {
  let permissions = {}
  menuItems.forEach(menuItem => {
    if (Array.isArray(menuItem.children) && menuItem.children.length > 0) {
      menuItem.permission = buildMenuItemPermission(menuItem.children)
    }
    if (menuItem.permission === null) {
      delete menuItem.permission
    } else if (Array.isArray(menuItem.permission)) {
      menuItem.permission.forEach(permission => {
        permissions[permission] = permission
      })
    } else if (typeof menuItem.permission === 'string') {
      permissions[menuItem.permission] = menuItem.permission
    }
  })
  if (permissions['*'] !== undefined) {
    return '*'
  }
  if (Object.keys(permissions).length > 1) {
    return Object.keys(permissions)
  } else if (Object.keys(permissions).length === 1) {
    return Object.keys(permissions)[0]
  }
  return null
}

export function filterMenuItems (menuItems, assignedPermissions) {
  const res = []
  // console.log('==========assignedPermissions: ', assignedPermissions)
  menuItems.forEach(menuItem => {
    const tmp = { ...menuItem }
    if (!tmp.hidden && hasPermission(tmp.permission, assignedPermissions)) {
      if (tmp.children) {
        tmp.children = filterMenuItems(tmp.children, assignedPermissions)
      }
      res.push(tmp)
    }
  })

  return res
}
