import Vue from "vue";

import Element from "element-ui";
import "element-ui/lib/theme-chalk/reset.css";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/common.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./lang"; // Internationalization

import "./directive/permission";
import "./directive/track";
import "./permission";
import appMixin from "./mixins/appMixin";

import MyTable from "./components/MyTable/table.vue";

Vue.config.productionTip = false;
Vue.component("MyTable", MyTable);

Vue.use(Element, {
  size: "mini",
  i18n: (key, value) => i18n.t(key, value),
});
Vue.use(appMixin);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
