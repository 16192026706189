import Layout from '@/views/layout/index'

const customerRouter = {
  path: '/customer',
  component: Layout,
  hidden: true,
  meta: {
    permission: 'customer-list'
  },
  children: [{
    path: 'list',
    meta: {
      permission: 'customer-list'
    },
    component: () => import('@/views/customer/list')
  }, {
    path: 'mine/list',
    meta: {
      permission: 'customer-mylist'
    },
    component: () => import('@/views/customer/mine/list')
  }, {
    path: 'pools/list',
    meta: {
      permission: 'customer-public'
    },
    component: () => import('@/views/customer/pools/list')
  }, {
    path: 'form',
    meta: {
      permission: 'customer-create'
    },
    component: () => import('@/views/customer/form')
  }, {
    path: 'detail/:id',
    meta: {
      permission: 'customer-list'
    },
    component: () => import('@/views/customer/detail')
  }, {
    path: 'shop/list',
    meta: {
      permission: 'customer-shop-list'
    },
    component: () => import('@/views/customer/shop/list')
  }, {
    path: 'shop/logs',
    meta: {
      permission: 'customer-shop-list'
    },
    component: () => import('@/views/customer/shop/logs')
  }, {
    path: 'shop/follow-records',
    meta: {
      permission: 'customer-shop-list'
    },
    component: () => import('@/views/customer/shop/follow-records')
  }]
}

export default customerRouter
