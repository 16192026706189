import Vue from 'vue'
import baseService from '../../services/base'

// initial state
const state = {
  ossPolicy: {},
  stsToken: {},
  navMenu: []
}

// getters
const getters = {}

// actions
const actions = {
  getOssPolicy: function ({ commit }, data = {}) {
    return new Promise((resolve, reject) => {
      baseService
        .getOssPolicy(data)
        .then((resp) => {
          commit('SET_OSS_POLICY', resp)
          resolve(resp)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getOssStsToken: function ({ commit }, data = {}) {
    return new Promise((resolve, reject) => {
      baseService
        .getOssStsToken(data)
        .then((resp) => {
          commit('SET_STS_TOKEN', resp)
          resolve(resp)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  refreshOssStsToken: function ({ commit, state }, data = {}) {
    return new Promise((resolve, reject) => {
      baseService
        .getOssStsToken(data)
        .then((resp) => {
          commit('SET_STS_TOKEN', resp)
          resolve(baseService.StsToken.toOssOptions(state.stsToken))
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getOssClient: function ({ commit, state }, data = {}) {
    if (!baseService.isStsTokenInvalid(state.stsToken)) {
      return baseService.getOssClient(state.stsToken, data)
    }
    return new Promise((resolve, reject) => {
      baseService
        .getOssStsToken(data)
        .then((resp) => {
          commit('SET_STS_TOKEN', resp)
          baseService.getOssClient(state.stsToken, data).then((ossClient) => {
            resolve(ossClient)
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadFile: function ({ commit, dispatch, state }, data = {}) {
    // return new Promise((resolve) => {
    //   dispatch('getOssClient').then((ossClient) => {
    //     baseService.uploadWithClient(data, ossClient)
    //   })
    // })
  },
  downloadFile: function ({ commit }, url) {
    if (url.indexOf('http') !== 0 && url.indexOf('//') !== 0) {
      url = process.env.VUE_APP_OSS_HOST + url
    }

    // window.open(url)

    let urlArr = url.split('/')
    let fileName = urlArr.pop()
    urlArr.push(encodeURIComponent(fileName))
    window.open(urlArr.join('/'))
  },
  getNavMenu: function ({ commit, state }, params = {}) {
    return new Promise((resolve, reject) => {
      baseService
        .getNavMenu(params)
        .then((resp) => {
          commit('SET_NAV_MENU', resp)
          resolve(resp)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

// mutations
const mutations = {
  SET_OSS_POLICY: (state, ossPolicy) => {
    if (ossPolicy.code !== undefined) {
      ossPolicy = ossPolicy.data
    }
    ossPolicy.expire = parseInt(ossPolicy.expire)
    Vue.set(state, 'ossPolicy', ossPolicy)
  },
  SET_STS_TOKEN: (state, resp) => {
    let { success, data } = resp
    if (!success) return
    const stsToken = {
      id: data.accessKeyId,
      secret: data.accessKeySecret,
      region: data.region,
      bucket: data.bucketName,
      expiration: parseInt(new Date(data.expiration).valueOf() / 1000),
      token: data.securityToken,
      dir: data.dir,
      callback: {
        url: data.callback.url,
        host: data.callback.host,
        body: data.callback.body,
        contentType: data.callback.contentType
      }
    }
    Vue.set(state, 'stsToken', stsToken)
  },
  SET_NAV_MENU: (state, navMenu) => {
    Vue.set(state, 'navMenu', navMenu)
    console.log('=======navMenu', navMenu)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
