<style>
.el-header {
  background-color: #373d41;
  color: #fff;
  line-height: 60px;
}

.el-aside {
  /*background-color: #333744;*/
  background-color: rgb(84, 92, 100);
}

.el-main {
  /*background-color: #e9eef3;*/
  color: #333;
}

header {
  width: 100%;
  /*padding: 0 20px;*/
  z-index: 1;
  box-sizing: border-box;
  position: fixed;
  top: 0;
}

header:after {
  display: inline-block;
  content: '';
  height: 100%;
  vertical-align: middle;
}

.menu {
  overflow-x: hidden;
  overflow-y: auto;
}

.main-wrapper {
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 100%;
}

.main-content {
  overflow-y: auto;
}
.el-main {
  overflow-x: hidden;
}
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.bg {
  width: 25%;
  min-width: 360px;
  cursor: pointer;
  background: #c6e2ff;
}
</style>

<template>
  <el-container>
    <x-header />
    <el-container class="main-wrapper">
      <x-aside />
      <el-container class="main-content">
        <el-main>
          <transition name="fade-transform" mode="out-in">
            <router-view />
          </transition>
        </el-main>
      </el-container>
    </el-container>
    <messageAlert />
  </el-container>
</template>

<script>
import { mapGetters } from 'vuex'
import XHeader from './components/XHeader'
import XAside from './components/XAside'
import message from '@/router/menus/message'
import messageAlert from './components/messageAlert.vue'
export default {
  components: { XAside, XHeader, messageAlert },
  data() {
    return {
      loading: false,
      msg: 'layout'
    }
  },
  methods: {
    handleCommand: function (command) {
      if (command === 'logout') {
        this.logout()
      } else if (command === 'modify-password') {
        this.$router.push({
          path: '/profile/password'
        })
      }
    },
    logout: function () {
      this.$store
        .dispatch('logout')
        .then((response) => {
          this.$router.replace({
            path: '/login'
          })
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    })
  }
}
</script>