import * as caiwu from '../../api/caiwu'

// initial state
const state = {}

// getters
const getters = {}

// actions
const actions = {
  getCaiwuMyInvoices ({ commit }, params) {
    return new Promise((resolve, reject) => {
      caiwu.myInvoice(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getCaiwuInvoices ({ commit }, params) {
    return new Promise((resolve, reject) => {
      caiwu.listInvoice(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  addCaiwuInvoice ({ commit }, data) {
    return new Promise((resolve, reject) => {
      caiwu.addInvoice(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  editCaiwuInvoice ({ commit }, data) {
    return new Promise((resolve, reject) => {
      caiwu.editInvoice(data.id, data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  deleteCaiwuInvoice ({ commit }, data) {
    return new Promise((resolve, reject) => {
      caiwu.deleteInvoice(data.id).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getSalaryDetails ({ commit }, params) {
    return new Promise((resolve, reject) => {
      caiwu.listSalaryDetail(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  addSalaryDetail ({ commit }, data) {
    return new Promise((resolve, reject) => {
      caiwu.addSalaryDetail(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  editSalaryDetail ({ commit }, data) {
    return new Promise((resolve, reject) => {
      caiwu.editSalaryDetail(data.id, data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  deleteSalaryDetail ({ commit }, data) {
    return new Promise((resolve, reject) => {
      caiwu.deleteSalaryDetail(data.id).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getDingtalkProjectStats ({ commit }, params) {
    return new Promise((resolve, reject) => {
      caiwu.listDingtalkProjectStat(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  addDingtalkProjectStat ({ commit }, data) {
    return new Promise((resolve, reject) => {
      caiwu.addDingtalkProjectStat(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  state,
  getters,
  actions
}
