import Layout from '@/views/layout/empty'

const authRouter = {
  path: '/auth',
  component: Layout,
  hidden: true,
  meta: {
    permission: '*'
  },
  children: [{
    path: 'dingtalk',
    component: () => import('@/views/auth/dingtalk'),
    meta: {
      permission: '*'
    }
  }, {
    path: 'login',
    meta: {
      permission: '*'
    },
    component: () => import('@/views/auth/login')
  }, {
    path: 'logout',
    meta: {
      permission: '@'
    },
    component: () => import('@/views/auth/logout')
  }]
}
if (process.env.VUE_APP_ENV_CONFIG === 'dev') {
  authRouter.children.push({
    path: 'password',
    meta: {
      permission: '*'
    },
    component: () => import('@/views/auth/password')
  })
}

export default authRouter
