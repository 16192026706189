import * as qaApi from "@/api/qa";
import * as types from "../mutation-types";

// initial state
const state = {
  term: {},
};

// getters
const getters = {};

// actions
const actions = {
  fetchTerm: function ({ dispatch, commit }, params) {
    return new Promise((resolve, reject) => {
      qaApi
        .termGet(params)
        .then((response) => {
          let { data } = response;
          commit(types.SET_QA_TERM, data.records[0]);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  SET_QA_TERM(state, obj) {
    state.term = obj;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
