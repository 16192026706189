import * as baseApi from '../api/base'
import * as StringUtil from '@/utils/StringUtil'
import { toTree } from '../utils/ArrayUtil'
import { getDate, parseTime } from '@/utils/DateUtil'
import OSS from 'ali-oss'

const MIN_POLICY_EXPIRATION = 5
const MIN_STS_EXPIRATION = 60 // S
const OssPolicy = {
  fields: {
    host: 'http://xxxx.oss-cn-shanghai.aliyuncs.com',
    policy: 'eyJleHBpcmF0aW9uIjoiMjAyMS0w',
    signature: '3wqAaCkRsPvnnXmJfNlG3IP2ayo=',
    expire: 1631171069,
    dir: '202109/',
    callback: '=',
    OSSAccessKeyId: 'xxxx',
    success_action_status: 200
  },
  toOptions (item) {
    return {
      // item : 'filename',
      policy: item.policy,
      OSSAccessKeyId: item.OSSAccessKeyId,
      success_action_status: item.success_action_status, // 让服务端返回200,不然，默认会返回204
      callback: item.callback,
      signature: item.signature
    }
  }
}
const StsToken = {
  fields: {
    id: 'AccessKeyId',
    secret: 'AccessKeySecret',
    host: 'host',
    region: 'region',
    bucket: 'bucket',
    expiration: 'time',
    token: 'stsToken',
    dir: 'Ym',
    callback: {
      url: '',
      body: '',
      contentType: 'application/json'
    }
  },
  toOssOptions (stsToken) {
    const options = {
      region: 'oss-' + stsToken.region,
      accessKeyId: stsToken.id,
      accessKeySecret: stsToken.secret,
      bucket: stsToken.bucket,
      stsToken: stsToken.token
    }
    if (stsToken.callback !== undefined && typeof stsToken.callback === 'object') {
      options.callback = stsToken.callback
    }
    return options
  }
}

const TermNav = {
  fields: {
    id: { label: 'ID' },
    parent_id: { label: '父ID' },
    title: {
      label: '名称',
      value: (item, prop) => {
        return item.name
      }
    },
    permission: {
      label: '权限',
      value: (item, prop) => {
        return item.biz_code
      }
    },
    url: {
      label: 'Url',
      value: (item, prop) => {
        return item.biz_value
      }
    },
    target: {
      label: 'Url',
      value: (item, prop) => {
        return item.features.target || ''
      }
    },
    features: { label: '特性' },
    status: { label: '状态' },
    createdAt: {
      value: (item, prop) => {
        return parseTime(item.created_at)
      },
      label: '创建时间'
    },
    updatedAt: {
      value: (item, prop) => {
        return parseTime(item.updated_at)
      },
      label: '更新时间'
    }
  },
  formatItem (item) {
    let _newItem = {}
    for (const prop in this.fields) {
      if (typeof this.fields[prop]['value'] === 'function') {
        _newItem[prop] = this.fields[prop]['value'](item, prop)
      } else {
        _newItem[prop] = item[prop] !== undefined ? item[prop] : ''
      }
    }
    return _newItem
  }
}
const TermCate = {
  fields: {
    id: { label: 'ID' },
    parent_id: { label: '父ID' },
    title: {
      label: '名称',
      value: (item, prop) => {
        return item.name
      }
    },
    biz_code: { label: '业务编码' },
    biz_value: { label: '业务值' },
    features: { label: '特性' },
    status: { label: '状态' },
    createdAt: {
      value: (item, prop) => {
        return parseTime(item.created_at)
      },
      label: '创建时间'
    },
    updatedAt: {
      value: (item, prop) => {
        return parseTime(item.updated_at)
      },
      label: '更新时间'
    }
  },
  formatItem (item) {
    for (const prop in this.fields) {
      if (typeof this.fields[prop]['value'] === 'function') {
        item[prop] = this.fields[prop]['value'](item, prop)
      }
    }
    return item
  }
}
// const TermProp = {}
// const TermTag = {}

export default {
  voNavMenu: TermNav.fields,
  getNavMenu: (params) => {
    return new Promise((resolve, reject) => {
      baseApi.getNavMenu(params).then(response => {
        if (Array.isArray(response.data.items)) {
          response.data.items.forEach((item, idx) => {
            response.data.items[idx] = TermNav.formatItem(item)
          })
          console.log(response.data.items)
          resolve(toTree(response.data.items))
        } else {
          resolve(response)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  voTermCate: TermCate,
  OssPolicy: OssPolicy,
  StsToken: StsToken,
  getOssPolicy: baseApi.getOssPolicy,
  getOssStsToken: baseApi.getOssStsToken,
  getOssClient: (stsToken, options = {}) => {
    return new Promise((resolve, reject) => {
      if (typeof stsToken === 'object' && stsToken.token !== undefined) {
        const ossOptions = Object.assign(StsToken.toOssOptions(stsToken), options)
        resolve(new OSS(ossOptions))
      } else {
        baseApi.getOssStsToken().then(stsTokenResp => {
          const ossOptions = Object.assign(StsToken.toOssOptions(stsTokenResp), options)
          resolve(new OSS(ossOptions))
        }).catch(error => {
          reject(error)
        })
      }
    })
  },
  isOssPolicyInvalid: (ossPolicy) => {
    if (typeof ossPolicy !== 'object' || ossPolicy.expire === undefined) {
      return true
    }
    if (typeof ossPolicy.expire !== 'number') {
      ossPolicy.expire = parseInt(ossPolicy.expire)
    }
    const now = (new Date()).valueOf() / 1000
    if (ossPolicy.expire - now > MIN_POLICY_EXPIRATION) {
      return false
    }

    return true
  },
  isStsTokenInvalid: (stsToken) => {
    if (typeof stsToken !== 'object' || stsToken.expiration === undefined) {
      return true
    }
    if (typeof stsToken.expiration !== 'number') {
      stsToken.expiration = parseInt(stsToken.expiration)
    }
    const now = (new Date()).valueOf() / 1000
    if (stsToken.expiration - now > MIN_STS_EXPIRATION) {
      return false
    }

    return true
  },
  uploadFile: (params, cb) => {
    const _that = this
    params.dir = params.dir || getDate('yyyyMM')
    params.name = params.name || getDate('yyyyMMddhhmmss') + StringUtil.randomNumber(8)
    // params.biz_data = { id: '111', type: 'test' }
    let callback = ''
    if (typeof params.biz_data === 'object') {
      callback = Object.assign({}, params.stsCallback || {})
      callback.customValue = params.biz_data
      let _body = ''
      for (const key in params.biz_data) {
        if (callback.contentType === 'application/json') {
          _body += ',"' + key + '":${x:' + key + '}'
        } else {
          _body += '&' + key + '=${x:' + key + '}'
        }
      }
      if (callback.contentType === 'application/json') {
        callback.body =
          callback.body.substr(0, callback.body.length - 1) + _body + '}'
      } else {
        callback.body += _body
      }
    }

    return new Promise((resolve, reject) => {
      _that.getOssClient({}, {}).then(ossClient => {
        _that.ossClient = ossClient

        let fileName = params.dir + '/' + params.name + StringUtil.getSuffix(params.file.name)
        _that.ossClient
          .put(fileName, params.file, {
            callback: callback
          })
          .then((result) => {
            // console.log("=======upload.result", result);
            if (typeof cb === 'function') {
              cb(result)
            }
            resolve(result)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    })
  },
  uploadWithPolicy: (params, ossPolicy) => {
    const suffix = StringUtil.getSuffix(params.file.name)
    const basename = getDate('yyyyMMddHHmmss') + StringUtil.randomNumber(8)
    const ossOptions = OssPolicy.toOptions(ossPolicy)
    ossOptions.key = ossPolicy.dir + basename + suffix

    return new Promise((resolve, reject) => {
      baseApi.uploadFile(ossPolicy.host, params.file, ossOptions).then(uploadResp => {
        if (uploadResp.status === 200) {
          uploadResp.data.url = '/' + ossOptions.key
          uploadResp.data.name = params.file.name
        }
        resolve(uploadResp)
      }).catch(error => {
        reject(error)
      })
    })
  },
  uploadWithClient: (params, ossClient) => {

  },
  downloadFile: (url) => {
    if (url.indexOf('http') !== 0 && url.indexOf('//') !== 0) {
      url = process.env.VUE_APP_VUE_APP_OSS_HOST + url
    }

    window.open(url)

    // let urlArr = url.split('/')
    // let fileName = urlArr.pop()
    // urlArr.push(encodeURIComponent(fileName))
    // window.open(urlArr.join('/'))
  },
  previewFile: (url) => {
    if (url.indexOf('http') !== 0 && url.indexOf('//') !== 0) {
      url = process.env.VUE_APP_VUE_APP_OSS_HOST + url
    }

    window.open(url)
  }
}
