import Vue from "vue";
import VueRouter from "vue-router";
import { loadModuleFiles } from "@/utils/ArrayUtil";

import Layout from "../views/layout/index";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 自动引入和注册modules下的文件
const modulesFiles = require.context("./modules", false, /\.js$/);
const modules = loadModuleFiles(modulesFiles);

Vue.use(VueRouter);

export const constantRouterMap = [
  {
    path: "/",
    name: "home",
    redirect: "/home",
    meta: {
      permission: "@",
    },
    component: Layout,
    children: [
      {
        path: "/home",
        component: () => import("@/views/home/index"),
        meta: {
          permission: "@",
        },
      },
    ],
  },
  {
    path: "/message/detail",
    name: "messageDetail",
    component: () => import("@/views/message/messageDetail/index"),
    meta: {
      permission: "@",
    },
  },
];

export default new VueRouter({
  // mode: 'history', // 后端支持可开
  routes: constantRouterMap.concat(Object.values(modules)),
});
