import request from "../utils/request";

import { BASE as baseUrl } from "./constant";

export function uploadFile(url, file, extraData) {
  let data = new FormData();
  for (let key in extraData) {
    data.append(key, extraData[key]);
  }
  data.append("file", file);
  return request({
    url: url,
    method: "POST",
    data: data,
  });
}

export function getOssPolicy(data = {}) {
  return request({
    url: baseUrl.OSS_POLICY,
    method: "POST",
    data: data,
  });
}

export function getOssStsToken(params) {
  return request({
    url: "/api-base/v2/oss/sts-token",
    method: "GET",
    params: params,
  });
}

export function getNavMenu(params = {}) {
  return request({
    url: baseUrl.TERM_TAXONOMY_NAV,
    method: "GET",
    params: params,
  });
}
