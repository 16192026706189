import Layout from '@/views/layout/index'

const adminRouter = {
  path: '/admin',
  component: Layout,
  hidden: true,
  meta: {
    permission: ['admin-list', 'admin-group-list', 'admin-menu-list']
  },
  children: [{
    path: 'list',
    meta: {
      permission: 'admin-list'
    },
    component: () => import('@/views/admin/list')
  }, {
    path: 'dept/list',
    meta: {
      permission: 'admin-dept-list'
    },
    component: () => import('@/views/admin/dept/list')
  }, {
    path: 'group/list',
    meta: {
      permission: 'admin-group-list'
    },
    component: () => import('@/views/admin/group/list')
  }, {
    path: 'permission/list',
    meta: {
      permission: 'admin-create-list'
    },
    component: () => import('@/views/admin/permission/list')
  }, {
    path: 'role/list',
    meta: {
      permission: 'admin-create-list'
    },
    component: () => import('@/views/admin/role/list')
  }, {
    path: 'role/form',
    meta: {
      permission: 'admin-create-list'
    },
    component: () => import('@/views/admin/role/form')
  }, {
    path: 'assign',
    meta: {
      permission: 'admin-create-list'
    },
    component: () => import('@/views/admin/assign')
  }, {
    path: 'menu/list',
    meta: {
      permission: 'admin-menu-list'
    },
    component: () => import('@/views/admin/menu/list')
  }]
}

export default adminRouter
