<template>
  <!-- 通知详情弹窗 -->
  <div class="message" v-show="dialogVisible">
    <div class="header">
      <div class="weight">公告：{{ rowItem.title }}</div>
      <i
        v-if="$route.path != '/message/detail'"
        class="el-icon-close close"
        @click="close"
      ></i>
    </div>
    <div class="content">
      <div>
        <span class="li"
          ><span class="weight2">发布人：</span
          >{{ rowItem.createUserName }}</span
        ><span class="li m_lr_72"
          ><span class="weight2">发布类型：</span
          >{{ rowItem.noticeTypeName }}</span
        ><span class="li"
          ><span class="weight2">发布时间：</span>{{ rowItem.createTime }}</span
        >
      </div>
      <div class="weight2">发布内容：</div>
      <div class="contentHtml" v-html="rowItem.noticeContent"></div>
      <div class="file" v-if="rowItem.files && rowItem.files.length > 0">
        <div class="file_title weight2">附件：</div>
        <div class="file_lis">
          <div v-for="item in rowItem.files" :key="item.url" class="file_li">
            <a :href="item.url">{{ item.name }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  props: {
    noticeTypeList: Array
  },
  data() {
    return {
      dialogVisible: false,
      rowItem: {}, // 公告内容
      type: 'read',
      loading: false
    }
  },
  methods: {
    init({ item, type }) {
      this.type = type
      let obj = JSON.parse(JSON.stringify(item))
      if (type !== 'read2') {
        obj.files = obj.files ? JSON.parse(obj.files) : []
        obj.createTime = dayjs(obj.createTime).format('YYYY-MM-DD HH:MM')
        if (!obj.noticeTypeName) {
          obj.noticeTypeName = this.noticeTypeList.find(
            (notice) => notice.id === obj.noticeTypeId
          )?.name
        }
      }
      this.rowItem = obj
      this.dialogVisible = true
    },
    close() {
      // 关闭弹窗按钮
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped>
@import './messageContentDialog.scss';
</style>
