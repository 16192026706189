<template>
  <el-container>
    <router-view/>
  </el-container>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      msg: 'layout'
    }
  },
  methods: {},
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    })
  }
}
</script>
