<template>
  <el-form label-position="right" label-width="80px" :model="passwordForm" :rules="rules" ref="passwordForm">
    <el-form-item label="姓名" prop="real_name" v-if="isAllowEmail">
      <el-input v-model="passwordForm.real_name"/>
    </el-form-item>
    <el-form-item label="账号" prop="login_name" v-if="isAllowLoginName">
      <el-input type="text" v-model="passwordForm.login_name"/>
    </el-form-item>
    <el-form-item label="旧密码" prop="old_password" v-if="isNeedOldPassword">
      <el-input type="password" v-model="passwordForm.old_password"/>
    </el-form-item>
    <el-form-item label="新密码" prop="new_password">
      <el-input type="password" v-model="passwordForm.new_password"/>
    </el-form-item>
    <el-form-item label="确认密码" prop="confirm_password">
      <el-input type="password" v-model="passwordForm.confirm_password"/>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="handleSubmit" :loading="loading">
        提交
      </el-button>
      <el-button type="success" @click="handleRandomPassword">随机密码</el-button>

    </el-form-item>
    <el-form-item v-if="isGeneratePassword" >
      <span style="padding-left: 10px;">生成的密码为: {{ generatePassword }}</span>
    </el-form-item>
  </el-form>
</template>

<script>

import { mapGetters } from 'vuex'
import {randomPassword} from "@/utils/StringUtil";
import {isStrongPassword} from "@/utils/PasswordUtil";

export default {
  name: 'ModifyPassword',
  data () {
    let validateNewPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.passwordForm.new_password !== '') {
          this.$refs.passwordForm.validateField('confirm_password')
        }
        if (!isStrongPassword(value)) {
          callback(new Error('至少包含大/小写字母、数字和特殊字符其中三者，长度不少于8位'))
        }
        callback()
      }

    }
    let validateConfirmPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.passwordForm.new_password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      title: '修改密码',
      passwordForm: {
        real_name: '',
        login_name: '',
        old_password: '',
        new_password: '',
        confirm_password: ''
      },
      generatePassword: '',
      rules: { // 就是我们在el-form中绑定的rules，进行表单的验证
        real_name: [ // 我们在el-form-item中绑定的prop，就是这里的name，需要大家注意，这具有唯一性
          { required: true, message: '请输入真实姓名', trigger: 'blur' } // require:是否必填，如不设置，则会根据校验规则自动生成;message:是提示的内容；trigger：是什么事件触发
        ],
        login_name: [ // 我们在el-form-item中绑定的prop，就是这里的name，需要大家注意，这具有唯一性
          { required: true, message: '请输入登录名', trigger: 'blur' } // require:是否必填，如不设置，则会根据校验规则自动生成;message:是提示的内容；trigger：是什么事件触发
        ],
        old_password: [ // 我们在el-form-item中绑定的prop，就是这里的name，需要大家注意，这具有唯一性
          { required: true, message: '请输入旧密码', trigger: 'blur' } // require:是否必填，如不设置，则会根据校验规则自动生成;message:是提示的内容；trigger：是什么事件触发
        ],
        new_password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: validateNewPass } // validator:对整个表单进行校验的方法，参数为一个回调函数。该回调函数会在校验结束后被调用，并传入两个参数：是否校验成功和未通过校验的字段。若不传入回调函数，则会返回一个 promise
        ],
        confirm_password: [
          { required: true, message: '请确认新密码', trigger: 'blur' },
          { validator: validateConfirmPass }
        ]
      },
      loading: false,
      dialogVisible: false
    }
  },
  mounted () {
    this.passwordForm.real_name = this.userInfo.user_name
    // console.log('dingtalkLogin: ', this.dingtalkLogin)
    // console.log('userInfo: ', this.userInfo)
    // console.log('passwordForm: ', this.passwordForm)
  },
  methods: {
    handleRandomPassword: function () {
      // this.item.password = randomPassword(12)
      const newPassword = randomPassword(12, true)
      console.log(isStrongPassword(newPassword))
      this.passwordForm.new_password = newPassword
      this.passwordForm.confirm_password = newPassword
      this.generatePassword = newPassword
    },
    handleSubmit: function () {
      let _that = this
      this.loading = true
      _that.$refs['passwordForm'].validate((valid) => {
        if (valid) {
          let _data = JSON.parse(JSON.stringify(this.passwordForm))
          if (_that.dingtalkLogin && !_that.isAllowEmail) {
            delete _data['real_name']
          }
          this.$store.dispatch('modifyPassword', _data).then((response) => {
            _that.$emit('handleModifyPassword', response)
            _that.$message({
              message: '修改成功',
              type: 'success'
            })
            for (let prop in _that.passwordForm) {
              _that.passwordForm[prop] = ''
            }
          }).catch((error) => {
            _that.$message({
              message: error.response.data.message,
              type: 'error'
            })
          }).finally(() => {
            _that.loading = false
          })
        } else {
          _that.loading = false
        }
      })
    }
  },
  computed: {
    isGeneratePassword: function () {
      return this.generatePassword !== '' && this.generatePassword === this.passwordForm.new_password
    },
    isAllowLoginName: function () {
      return this.dingtalkLogin && !this.userInfo.loginName
    },
    isAllowEmail: function () {
      return this.dingtalkLogin && this.userInfo.loginName === ''
    },
    isNeedOldPassword: function () {
      return !this.dingtalkLogin
    },
    ...mapGetters({
      userInfo: 'userInfo',
      dingtalkLogin: 'dingtalkLogin'
    })
  }
}
</script>
