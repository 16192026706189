export function parseUrlParams (url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
}

export function randomString (length = 32) {
  const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz123456789'
  let result = ''
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}

export function randomNumber (length = 6) {
  const chars = '123456789'
  let result = ''
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}

export function randomPassword (length = 8, symbol = true) {
  if (length < 6) {
    length = 6
  }
  const result = []
  const chars = {
    upper: 'ABCDEFGHJKMNPQRSTWXYZ',
    lower: 'abcdefhijkmnprstwxyz',
    number: '0123456789',
    symbol: '_-$%&@+!'
  }

  if (typeof symbol === 'string') {
    chars.symbol = symbol
  } else if (symbol === false) {
    delete chars.symbol
  }
  let allChars = ''
  for (const type in chars) {
    result.push(chars[type].charAt(Math.floor(Math.random() * chars[type].length)))
    allChars += chars[type]
  }
  for (let i = 0; i < length - Object.keys(chars).length; i++) {
    result.push(allChars.charAt(Math.floor(Math.random() * allChars.length)))
  }
  return result.slice().sort(() => Math.random() - 0.5).join('')
}

export function getSuffix (filename) {
  const pos = filename.lastIndexOf('.')
  return pos !== -1 ? filename.substring(pos) : ''
}

export function getBasename (filename, suffix = true) {
  let name = filename.substr(filename.lastIndexOf('/') + 1)
  if (!suffix) {
    const _suffix = getSuffix(name)
    return _suffix !== '' ? name.substr(0, name.length - _suffix.length) : name
  }
  return name
}

export function charLength (str) {
  if (str === undefined || str === null) {
    return 0
  }
  let len = 0
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 128) {
      len += 2
    } else {
      len++
    }
  }
  return len
}

export function truncate (str, len) {
  let strLen = 0
  let s = ''
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 128) {
      strLen += 2
    } else {
      strLen++
    }
    if (strLen <= len) {
      s += str.charAt(i)
    } else {
      return s
    }
  }
  return s
}
