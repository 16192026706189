import permCode from '../config/permCode'
import enumOptions from '../config/enum'
import { mapGetters } from 'vuex'
import { parseDate } from '@/utils/DateUtil'

export default {
  install (Vue) {
    Vue.mixin({
      props: {
        visibleFields: {
          type: [Array, Boolean, undefined],
          default: function () {
            return []
          }
        },
        invisibleFields: {
          type: [Array, Boolean, undefined],
          default: function () {
            return []
          }
        }
      },
      methods: {
        isFieldVisible (prop) {
          if (Array.isArray(this.invisibleFields) && this.invisibleFields.length > 0) {
            return this.invisibleFields.indexOf(prop) === -1
          } else if (Array.isArray(this.visibleFields) && this.visibleFields.length > 0) {
            return this.visibleFields.indexOf(prop) > -1
          }
          return true
        },
        formatDate (val, format) {
          return parseDate(val, format)
        },
        booleanOptionName (val) {
          return val === true || val === 1 ? '是' : '否'
        },
        enumOptionName (prop, val) {
          let options = this.enumOptions
          let result = val
          prop.split('.').forEach(key => {
            options = options[key] || []
          })
          options.forEach(valItem => {
            if (valItem.id === val) {
              result = valItem.name
            }
          })
          return result
        }
      },
      computed: {
        ...mapGetters({
          userInfo: 'userInfo'
        }),
        permCode () {
          return permCode
        },
        enumOptions () {
          return enumOptions
        }
      }
    })
  }
}
