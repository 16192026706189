<template>
  <el-aside
    :class="asideClass"
    :width.sync="asideWidth"
  >
    <el-menu
      default-active="2"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      :collapse="isCollapse"
      router
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <el-tooltip
        effect="dark"
        :content="isCollapse ? '展开导航' : '收起导航'"
        placement="right"
      >
        <li
          class="el-menu-item item-collapse"
          @click="toggleAside"
        >
          <i :class="isCollapse ? 'el-icon-d-arrow-right' : 'el-icon-d-arrow-left'" />
        </li>
      </el-tooltip>
      <menu-item
        v-for="item in menuItems"
        :key="item.url"
        :item="item"
      />
    </el-menu>
  </el-aside>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import MenuItem from "./MenuItem";
export default {
  name: "XAside",
  components: { MenuItem },
  data() {
    return {
      asideClass: "",
      asideWidth: "250px",
      isCollapse: false,
    };
  },
  mounted: function () {
    this.menuItems.length === 0 && this.initMenuItems();
  },
  methods: {
    initMenuItems() {
      this.$store
        .dispatch("buildAsideMenuItems")
        .then((resp) => {})
        .catch((error) => {
          console.log(error);
        });
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath)
    },
    toggleAside() {
      this.asideWidth = this.isCollapse ? "250px" : "60px";
      this.asideClass = this.isCollapse ? "" : "sidebar-collapsed";
      this.isCollapse = !this.isCollapse;
    },
  },
  computed: {
    ...mapGetters({
      menuItems: "menuItems",
    }),
  },
};
</script>

<style>
.el-menu {
  border-right: 0;
}
.el-menu-item.item-collapse {
  text-align: right;
  border: none;
  height: 40px;
  line-height: 40px;
}
.el-menu-item:focus,
.el-menu-item:hover {
  background-color: rgb(67, 74, 80) !important;
}
.el-menu-item:hover i {
  color: #fff;
}
</style>
