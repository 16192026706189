export const toThousands = (a) => {
  //金额千分位转化 小数部分保留2位 不足补零
  let num = ((a || 0) * 1).toFixed(2)
  let int = parseInt(num) + ''
  let result = ''
  while (int.length > 3) {
    result = ',' + int.slice(-3) + result
    int = int.slice(0, int.length - 3)
  }
  if (int) {
    result = int + result
  }
  if (num.indexOf('.') != -1) {
    let fixed = num.slice(num.indexOf('.') + 1, num.indexOf('.') + 3)
    result = result + '.' + (fixed.length < 2 ? fixed + '0' : fixed)
  } else {
    result = result + '.00'
  }
  return result
}

export const toThousands2 = (a) => {
  //整数 千分位转化
  let num = (parseInt(a) || 0).toString()
  let result = ''
  while (num.length > 3) {
    result = ',' + num.slice(-3) + result
    num = num.slice(0, num.length - 3)
  }
  if (num) {
    result = num + result
  }
  return result
}

export const toPercentage = (a) => {
  //将比率类型数据转化为百分比 保留百分比状态两位小数
  if (!a) {
    return 0
  } else {
    return Number((a * 100).toFixed(2)) + '%'
  }
}

export function toDecimal(val, pos = 0, replace = false) {
  val = val || 0
  if (!val) return '-'

  let str = val.toString()
  if (str.indexOf('.') < 0) {
    let strLen = str.length
    if (pos) {
      str += '.'
      while (str.length <= strLen + pos) {
        str += '0'
      }
    }
  } else {
    str = Math.floor(val * Math.pow(10, pos)) / Math.pow(10, pos)
  }

  if (replace) {
    //  str = str.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
    str = str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return str
}

export function WNFormat(value) {
  if (!value) return 0
  value = +value
  let str = ''
  let k = 10000
  let sizes = ['', '万', '亿', '万亿']
  let i
  if (value > k) {
    i = Math.floor(Math.log(value) / Math.log(k))
    str = value / Math.pow(k, i)
    if (String(str).indexOf('.') !== -1) {
      str = str.toFixed(2)
    }
    str = str + sizes[i]
  } else {
    str = value.toFixed()
  }
  return str
}

export function numberToChinese(n) {
  const max = 9999999999999999.99
  if (n >= max) {
    return n
  }

  const fraction = ['角', '分', '毫', '厘']
  const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
  const unit = [
    ['元', '万', '亿', '兆'],
    ['', '拾', '佰', '仟']
  ]
  let head = n < 0 ? '欠' : ''
  n = Math.abs(n)

  let s = ''

  for (let i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '')
  }
  s = s || '整'
  n = Math.floor(n)

  for (let i = 0; i < unit[0].length && n > 0; i++) {
    let p = ''
    for (let j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p
      n = Math.floor(n / 10)
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s
  }
  return (
    head +
    s
      .replace(/(零.)*零元/, '元')
      .replace(/(零.)+/g, '零')
      .replace(/^整$/, '零元整')
  )
}
