import request from '../utils/request'
import { V1 as v1Url } from './constant'

export function treeOption (params) {
  return request({
    url: v1Url.DEVICE_OPTION_TREE,
    method: 'GET',
    params: params
  })
}

export function listOption (params) {
  return request({
    url: v1Url.DEVICE_OPTION_LIST,
    method: 'GET',
    params: params
  })
}

export function addOption (data) {
  return request({
    url: v1Url.DEVICE_OPTION_CREATE,
    method: 'POST',
    data: data
  })
}

export function editOption (id, data) {
  return request({
    url: v1Url.DEVICE_OPTION_UPDATE + id,
    method: 'PUT',
    data: data
  })
}

export function listProduct (params) {
  return request({
    url: v1Url.DEVICE_PRODUCT_LIST,
    method: 'GET',
    params: params
  })
}

export function addProduct (data) {
  return request({
    url: v1Url.DEVICE_PRODUCT_CREATE,
    method: 'POST',
    data: data
  })
}

export function editProduct (id, data) {
  return request({
    url: v1Url.DEVICE_PRODUCT_UPDATE + id,
    method: 'PUT',
    data: data
  })
}

export function listItem (params) {
  return request({
    url: v1Url.DEVICE_ITEM_LIST,
    method: 'GET',
    params: params
  })
}

export function addItem (data) {
  return request({
    url: v1Url.DEVICE_ITEM_CREATE,
    method: 'POST',
    data: data
  })
}

export function editItem (id, data) {
  return request({
    url: v1Url.DEVICE_ITEM_UPDATE + id,
    method: 'PUT',
    data: data
  })
}

export function listItemLog (params) {
  return request({
    url: v1Url.DEVICE_ITEM_LOG_LIST,
    method: 'GET',
    params: params
  })
}

export function addItemLog (data) {
  return request({
    url: v1Url.DEVICE_ITEM_LOG_CREATE,
    method: 'POST',
    data: data
  })
}

export function editItemLog (id, data) {
  return request({
    url: v1Url.DEVICE_ITEM_LOG_UPDATE + id,
    method: 'PUT',
    data: data
  })
}
