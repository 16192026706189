import Layout from "@/views/layout/index";
import permCode from "@/config/permCode";

export default [
  {
    path: "/dy-ad",
    component: Layout,
    hidden: true,
    meta: {},
    children: [
      {
        path: "access-token",
        meta: {
          permission: permCode.DY_AD_ACCESS_TOKEN_LIST,
        },
        component: () => import("@/views/dy-ad/access-token/index"),
      }
    ],
  },
];
