import Layout from '@/views/layout/index'

const deviceRouter = {
  path: '/device',
  component: Layout,
  hidden: true,
  meta: {
    permission: 'caiwu-*'
  },
  children: [{
    path: 'option',
    meta: {
      permission: 'caiwu-device-option'
    },
    component: () => import('@/views/device/option')
  }, {
    path: 'product',
    meta: {
      permission: 'caiwu-device-product'
    },
    component: () => import('@/views/device/product')
  }, {
    path: 'item',
    meta: {
      permission: 'caiwu-device-item'
    },
    component: () => import('@/views/device/item')
  }]
}

export default deviceRouter
