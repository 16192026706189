import Vue from "vue";
import * as auth from "../../api/auth";
import * as message from "../../api/message";
import * as types from "../mutation-types";
import * as authUtil from "@/utils/AuthUtil";
import menuItems from "@/router/menu";
import { getAuth as getV1Auth } from "../../api/admin";
import { toTree, toTreeOptions } from "@/utils/ArrayUtil";

// initial state
const state = {
  accessToken: "",
  permissions: [],
  userInfo: {},
  menuItems: [],
  suggest: [], //人员列表
  deptOptions: [], //部门列表
  deptTreeOptions: [], //树形部门列表
  messageTypeList: [], //消息通知类型列表(所有)
  message: "", //消息名称
};

// getters
const getters = {};

// actions
const actions = {
  loginByDingtalk({ commit }, data) {
    return new Promise((resolve, reject) => {
      auth
        .loginByDingtalk(data)
        .then((response) => {
          response.dingtalkLogin = true;
          commit(types.LOGIN, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * 登录
   * @param commit
   * @param loginInfo
   * @returns {Promise<any>}
   */
  loginByUsername: function ({ commit }, loginInfo) {
    return new Promise((resolve, reject) => {
      auth
        .login(loginInfo.username, loginInfo.password)
        .then((response) => {
          if (response.success) {
            commit(types.LOGIN, response);
            resolve(response);
          } else {
            reject(response)
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * 退出
   * @param commit
   * @returns {Promise<any>}
   */
  logout: function ({ commit }) {
    return new Promise((resolve, reject) => {
      auth
        .logout()
        .then((response) => {
          commit(types.LOGOUT);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchPermission: function ({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      auth
        .getPermission()
        .then((response) => {
          // commit(types.SET_PERMISSION, authUtil.formatPermission(response.data))
          getV1Auth()
            .then((resp) => {
              const permissions = [
                ...authUtil.formatPermission(response.data),
                ...resp.permissions,
              ];
              commit(types.SET_PERMISSION, permissions);
            })
            .finally(() => {
              dispatch("buildAsideMenuItems").then(() => {});
            });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          dispatch("buildAsideMenuItems").then(() => {});
        });
    });
  },

  modifyPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      auth
        .modifyPassword(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setPermission: function ({ commit }, permissions) {
    commit(types.SET_PERMISSION, permissions);
  },

  refreshUserInfo: function ({ commit }) {
    commit(types.SET_USER_INFO, authUtil.getUserInfo());
    commit(types.SET_ACCESS_TOKEN, authUtil.getToken());
  },

  setMenuItems({ commit }, menuItems) {
    commit("SET_MENU_ITEMS", menuItems);
  },

  getNavItems(params = {}) {
    return auth.getNavItems(params);
  },

  buildAsideMenuItems({ commit, state }) {
    return new Promise((resolve) => {
      let _menuItems = authUtil.filterMenuItems(menuItems, state.permissions);
      commit("SET_MENU_ITEMS", _menuItems);
      resolve(_menuItems);
    });
  },

  //查询成员列表
  suggestAdmins2({ commit }, params) {
    return new Promise((resolve, reject) => {
      auth
        .suggest(params)
        .then((response) => {
          commit("SET_SUGGEST", response.data.items);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //查询部门列表
  listAdminDepts({ commit }, params) {
    return new Promise((resolve, reject) => {
      auth
        .listDept(params)
        .then((response) => {
          commit("SET_DEPT_OPTIONS", response.data.items);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //查询通知类型列表
  listAdminMessageTypes({ commit }, params) {
    return new Promise((resolve, reject) => {
      message
        .noticeTypeList(params)
        .then((response) => {
          commit("SET_MESSAGE_TYPES", response.data.records);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  LOGIN(state, response) {
    let accessToken = response.data.token;
    let userInfo = response.data.userDto;
    // 兼容之前
    userInfo.user_id = parseInt(userInfo.id);
    userInfo.user_name = userInfo.name;
    delete userInfo.id;
    delete userInfo.name;
    if (typeof response.data.userDto.extraMsg === "string") {
      userInfo = Object.assign(
        userInfo,
        JSON.parse(response.data.userDto.extraMsg)
      );
      delete userInfo.extraMsg;
    }
    state.userInfo = userInfo;
    state.accessToken = accessToken;
    authUtil.setToken(accessToken);
    authUtil.setUserInfo(userInfo);
  },
  LOGOUT(state) {
    state.userInfo = [];
    state.accessToken = "";
    state.permissions = [];
    authUtil.removeToken();
    authUtil.removeUserInfo();
  },
  SET_USER_INFO(state, obj) {
    state.userInfo = typeof obj === "undefined" ? {} : obj;
  },
  SET_ACCESS_TOKEN(state, accessToken) {
    state.accessToken = typeof accessToken === "string" ? accessToken : "";
  },
  SET_PERMISSION(state, permissions) {
    state.permissions = permissions;
  },
  SET_MENU_ITEMS: (state, menuItems) => {
    Vue.set(state, "menuItems", menuItems);
  },
  SET_DEPT_OPTIONS(state, array) {
    const deptTree = toTree(array);
    const deptTreeOptions = toTreeOptions(deptTree);
    Vue.set(state, "deptOptions", array);
    Vue.set(state, "deptTreeOptions", deptTreeOptions);
  },
  SET_SUGGEST(state, array) {
    Vue.set(state, "suggest", array);
  },
  SET_MESSAGE_TYPES(state, array) {
    Vue.set(state, "messageTypeList", array);
  },
  SET_MESSAGE(state, message) {
    Vue.set(state, "message", message);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
