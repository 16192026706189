import Layout from '@/views/layout/index'

const caiwuRouter = {
  path: '/caiwu',
  meta: {
    permission: 'caiwu-*'
  },
  component: Layout,
  hidden: true,
  children: [{
    path: 'salary-detail',
    meta: {
      permission: 'caiwu-salary'
    },
    component: () => import('@/views/caiwu/salary-detail')
  }, {
    path: '/my/invoice',
    meta: {
      permission: '@'
    },
    component: () => import('@/views/caiwu/invoice-my')
  }, {
    path: 'invoice',
    meta: {
      permission: 'caiwu-travel'
    },
    component: () => import('@/views/caiwu/invoice')
  }, {
    path: 'travel',
    meta: {
      permission: 'caiwu-travel'
    },
    component: () => import('@/views/caiwu/travel')
  }, {
    path: 'travel-bank',
    meta: {
      permission: 'caiwu-travel'
    },
    component: () => import('@/views/caiwu/travel-bank')
  }, {
    path: 'koufu',
    meta: {
      permission: 'caiwu-koufu'
    },
    component: () => import('@/views/caiwu/koufu')
  }, {
    path: 'excel',
    meta: {
      permission: 'caiwu-koufu'
    },
    component: () => import('@/views/caiwu/excel')
  // }, {
  //   path: 'dd-project',
  //   meta: {
  //     permission: 'caiwu-dd-project'
  //   },
  //   component: () => import('@/views/caiwu/dd-project')
  }]
}

export default caiwuRouter
