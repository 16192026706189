import request from '../utils/request'
import { V1 as v1Url } from './constant'

export function list (params) {
  return request({
    url: v1Url.CUSTOMER_LIST,
    method: 'GET',
    params: params
  })
}

export function mylist (params) {
  return request({
    url: v1Url.CUSTOMER_MY_LIST,
    method: 'GET',
    params: params
  })
}

export function publicList (params) {
  return request({
    url: v1Url.CUSTOMER_PUBLIC,
    method: 'GET',
    params: params
  })
}

export function view (params) {
  return request({
    url: v1Url.CUSTOMER_VIEW,
    method: 'GET',
    params: params
  })
}

export function add (data) {
  return request({
    url: v1Url.CUSTOMER_CREATE,
    method: 'POST',
    data: data
  })
}

export function edit (id, data) {
  return request({
    url: v1Url.CUSTOMER_UPDATE,
    method: 'POST',
    params: { id: id },
    data: data
  })
}

export function receive (data) {
  return request({
    url: v1Url.CUSTOMER_RECEIVE,
    method: 'POST',
    data: data
  })
}

export function transfer (data) {
  return request({
    url: v1Url.CUSTOMER_TRANSFER,
    method: 'POST',
    data: data
  })
}

export function listFollowRecord (params) {
  return request({
    url: v1Url.CUSTOMER_FOLLOW_RECORDS,
    method: 'GET',
    params: params
  })
}

export function addFollowRecord (data) {
  return request({
    url: v1Url.CUSTOMER_FOLLOW_RECORDS_CREATE,
    method: 'POST',
    data: data
  })
}

export function listShop (params) {
  return request({
    url: v1Url.CUSTOMER_SHOP,
    method: 'GET',
    params: params
  })
}

export function suggestShop (params) {
  return request({
    url: v1Url.CUSTOMER_SHOP_SUGGEST,
    method: 'GET',
    params: params
  })
}

export function addShop (data) {
  return request({
    url: v1Url.CUSTOMER_SHOP_CREATE,
    method: 'POST',
    data: data
  })
}

export function editShop (id, data) {
  return request({
    url: v1Url.CUSTOMER_SHOP_UPDATE,
    method: 'POST',
    params: { id: id },
    data: data
  })
}

export function listShopLogs (params) {
  return request({
    url: v1Url.CUSTOMER_SHOP_LOG,
    method: 'GET',
    params: params
  })
}

export function listShopFollowRecord (params) {
  return request({
    url: v1Url.CUSTOMER_SHOP_FOLLOW_RECORDS,
    method: 'GET',
    params: params
  })
}

export function addShopFollowRecord (data) {
  return request({
    url: v1Url.CUSTOMER_SHOP_FOLLOW_RECORDS_CREATE,
    method: 'POST',
    data: data
  })
}
