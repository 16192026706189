// import Vue from 'vue'
import Cookies from 'js-cookie'

const cookieDomainLevel = process.env.VUE_APP_COOKIE_DOMAIN || 0

const cacheUtil = {
  // cookie
  getCookie: function (name) {
    let val = Cookies.get(name)
    return cacheUtil.prepareGet(val)
  },
  setCookie: function (name, val, attributes = {}) {
    if (!name) return
    val = cacheUtil.prepareSet(val)
    if (attributes['domain']) {
      attributes['domain'] = cacheUtil.getCookieDomain()
    }
    return Cookies.set(name, val, attributes)
  },
  removeCookie: function (name, attributes = {}) {
    if (attributes['domain']) {
      attributes['domain'] = cacheUtil.getCookieDomain()
    }
    return Cookies.remove(name, attributes)
  },

  // seesion
  getSession: function (name) {
    if (!name) return
    let val = window.sessionStorage.getItem(name)
    return cacheUtil.prepareGet(val)
  },
  setSession: function (name, content) {
    if (!name) return
    content = cacheUtil.prepareSet(content)
    window.sessionStorage.setItem(name, content)
  },
  removeSession: function (name) {
    if (!name) return
    window.sessionStorage.removeItem(name)
  },

  // local
  getLocal: function (name) {
    if (!name) return
    let val = window.localStorage.getItem(name)
    return cacheUtil.prepareGet(val)
  },
  setLocal: function (name, content) {
    if (!name) return
    content = cacheUtil.prepareSet(content)
    window.localStorage.setItem(name, content)
  },
  removeLocal: function (name) {
    if (!name) return
    window.localStorage.removeItem(name)
  },
  prepareSet (val) {
    if (typeof val === 'object' || val instanceof Array) {
      val = JSON.stringify(val)
    }
    return val
  },
  prepareGet (val) {
    let jsonArr = ['{', '[']
    if (val !== null && val !== undefined && jsonArr.indexOf(val[0]) > -1) {
      try {
        val = JSON.parse(val)
      } catch (e) {

      }
    }
    return val
  },
  getCookieDomain: function () {
    let domain = document.domain
    if (cookieDomainLevel > 0) {
      let arr = domain.split('.')
      let domainLen = arr.length - cookieDomainLevel
      if (domainLen >= 2) {
        for (let i = 0; i < cookieDomainLevel; i++) {
          arr.shift()
        }
      }
      domain = arr.join('.')
    }
    return domain
  }
}

export default cacheUtil
