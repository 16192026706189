import Vue from 'vue'
import store from '@/store'
import { hasPermission } from '../utils/AuthUtil'

Vue.directive('permission', {
  inserted: function (el, binding, vnode) {
    if (typeof binding.value === 'string' && (binding.value === '*' || (binding.value === '@' && store.getters.accessToken))) {
      return true
    }

    let value = binding.value
    if (typeof value === 'string') {
      value = [ value ]
    }
    if (value && value instanceof Array && value.length > 0) {
      const permissions = store.getters && store.getters.permissions
      let _allowed = false
      try {
        _allowed = hasPermission(value, permissions)
      } catch (e) {
      }

      if (!_allowed) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      console.error(`need permission! Like v-permission="['xxx-list','xxx-create']"`, binding.value)
      return false
    }
  }
})
