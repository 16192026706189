import request from '../utils/request'
import { V1 as v1Url } from './constant'

export function list (params) {
  return request({
    url: v1Url.XUQIU_LIST,
    method: 'GET',
    params: params
  })
}

export function myPublish (params) {
  return request({
    url: v1Url.XUQIU_MY_PUBLISH,
    method: 'GET',
    params: params
  })
}

export function waitHandle (params) {
  return request({
    url: v1Url.XUQIU_WAIT_HANDLE,
    method: 'GET',
    params: params
  })
}

export function myHandle (params) {
  return request({
    url: v1Url.XUQIU_MY_HANDLE,
    method: 'GET',
    params: params
  })
}

export function handle (data) {
  return request({
    url: v1Url.XUQIU_HANDLE,
    method: 'POST',
    data: data
  })
}

export function add (data) {
  return request({
    url: v1Url.XUQIU_CREATE,
    method: 'POST',
    data: data
  })
}

export function edit (id, data) {
  return request({
    url: v1Url.XUQIU_UPDATE,
    method: 'POST',
    params: { id: id },
    data: data
  })
}

export function view (id, params) {
  params.id = id
  return request({
    url: v1Url.XUQIU_VIEW,
    method: 'GET',
    params: params
  })
}

export function listLog (params) {
  return request({
    url: v1Url.XUQIU_LOG,
    method: 'GET',
    params: params
  })
}

export function addLog (data) {
  return request({
    url: v1Url.XUQIU_LOG_CREATE,
    method: 'POST',
    data: data
  })
}

export function editLog (id, data) {
  return request({
    url: v1Url.XUQIU_LOG_UPDATE,
    method: 'POST',
    params: { id: id },
    data: data
  })
}

export function treeType (params) {
  return request({
    url: v1Url.XUQIU_TYPE_TREE,
    method: 'GET',
    params: params
  })
}

export function listType (params) {
  return request({
    url: v1Url.XUQIU_TYPE,
    method: 'GET',
    params: params
  })
}

export function addType (data) {
  return request({
    url: v1Url.XUQIU_TYPE_CREATE,
    method: 'POST',
    data: data
  })
}

export function editType (id, data) {
  return request({
    url: v1Url.XUQIU_TYPE_UPDATE,
    method: 'POST',
    params: { id: id },
    data: data
  })
}
