const DATE_FORMAT = 'yyyy-MM-dd'
const DATETIME_FORMAT = 'yyyy-MM-dd hh:mm'

function _dateFormat (date, fmt) {
  let o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    'S': date.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
  }
  return fmt
}

export function toDate (date) {
  return isDate(date) ? new Date(date) : null
}

export function isDate (date) {
  if (date === null || date === undefined) return false
  if (isNaN(new Date(date).getTime())) return false
  if (Array.isArray(date)) return false // deal with `new Date([ new Date() ]) -> new Date()`
  return true
}

export function isDateObj (val) {
  return val instanceof Date
}

export function isDateNumber (val) {
  let length = (val + '').length
  return /^\d+?$/.test(val) && (length === 10 || length === 13)
}

/**
 * @param date 2021/03/15 | 2021-03-15 | 1621577158 | 1621577158362 | dateObj
 * @param format
 * @returns {string|*}
 */
export function formatDate (date, format = 'yyyy-MM-dd') {
  let _date
  if (isDateObj(date)) {
    _date = date
  } else if (isDateNumber(date)) {
    if (('' + date).length === 10) date = parseInt(date) * 1000
    _date = new Date(date)
  } else {
    _date = toDate(date)
    if (!date) return ''
  }
  return _dateFormat(_date, format)
}

export function parseDate (date, cFormat = DATE_FORMAT) {
  if (arguments.length === 0) {
    return null
  }
  return formatDate(date, cFormat || DATE_FORMAT)
}

export function parseTime (time, cFormat = DATETIME_FORMAT) {
  if (arguments.length === 0) {
    return null
  }
  return formatDate(time, cFormat || DATETIME_FORMAT)
}

export function getDate (cFormat = DATE_FORMAT) {
  return formatDate(new Date(), cFormat || DATE_FORMAT)
}

export function getDatetime (cFormat = DATETIME_FORMAT) {
  return formatDate(new Date(), cFormat || DATETIME_FORMAT)
}

/**
 * @param startDate 2020/12/20
 * @param endDate
 * @param sameDay
 * @returns {number}
 */
export function dateDiff (startDate, endDate, sameDay = 0) {
  let startTime = new Date(Date.parse(startDate.replace(/-/g, '/'))).getTime()
  let endTime = new Date(Date.parse(endDate.replace(/-/g, '/'))).getTime()
  return Math.abs((startTime - endTime)) / (1000 * 60 * 60 * 24) + (sameDay ? 1 : 0)
}
