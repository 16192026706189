import Layout from "@/views/layout/index";
import permCode from "@/config/permCode";

export default [
  {
    path: "/message",
    component: Layout,
    hidden: true,
    meta: {},
    children: [
      {
        path: "type",
        meta: {
          permission: permCode.MESSAGE_TYPE,
        },
        component: () => import("@/views/message/messageType/index"),
      },
      {
        path: "create",
        meta: {
          permission: permCode.MESSAGE_OPERATE,
        },
        component: () => import("@/views/message/createMessage/index"),
      },
      {
        path: "my",
        meta: {
          permission: permCode.MESSAGE_VIEW,
        },
        component: () => import("@/views/message/myMessage/index"),
      },
    ],
  },
];
