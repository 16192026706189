import * as device from '../../api/device'

// initial state
const state = {}

// getters
const getters = {}

// actions
const actions = {
  getDeviceOptionTree ({ commit }, params) {
    return new Promise((resolve, reject) => {
      device.treeOption(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getDeviceProps ({ commit }, params) {
    return new Promise((resolve, reject) => {
      device.treeOption(params).then(response => {
        let props = []
        response.forEach(function (propItem) {
          let children = []
          propItem.children.forEach(function (childItem) {
            children.push({
              id: childItem.id,
              name: childItem.name,
              pvId: propItem.id + ':' + childItem.id,
              pvName: propItem.name + ':' + childItem.name
            })
          })
          props.push({
            id: propItem.id,
            name: propItem.name,
            children: children
          })
        })
        resolve(props)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getDeviceOptions ({ commit }, params) {
    return new Promise((resolve, reject) => {
      device.listOption(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  addDeviceOption ({ commit }, data) {
    return new Promise((resolve, reject) => {
      device.addOption(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  editDeviceOption ({ commit }, data) {
    return new Promise((resolve, reject) => {
      device.editOption(data.id, data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getDeviceProducts ({ commit }, params) {
    return new Promise((resolve, reject) => {
      device.listProduct(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  addDeviceProduct ({ commit }, data) {
    return new Promise((resolve, reject) => {
      device.addProduct(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  editDeviceProduct ({ commit }, data) {
    return new Promise((resolve, reject) => {
      device.editProduct(data.id, data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getDeviceItems ({ commit }, params) {
    return new Promise((resolve, reject) => {
      device.listItem(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  addDeviceItem ({ commit }, data) {
    return new Promise((resolve, reject) => {
      device.addItem(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  editDeviceItem ({ commit }, data) {
    return new Promise((resolve, reject) => {
      device.editItem(data.id, data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getDeviceItemLogs ({ commit }, params) {
    return new Promise((resolve, reject) => {
      device.listItemLog(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  addDeviceItemLog ({ commit }, data) {
    return new Promise((resolve, reject) => {
      device.addItemLog(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  editDeviceItemLog ({ commit }, data) {
    return new Promise((resolve, reject) => {
      device.editItemLog(data.id, data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  state,
  getters,
  actions
}
