export default [{
  'permission': ['customer-shop-list', 'customer-mylist'],
  'title': '客户',
  sortOrder: 30,
  'icon': 'el-icon-menu',
  'url': '#customer',
  'children': [{
    'permission': 'customer-list',
    'title': '客户列表',
    'url': '/customer/list'
  }, {
    'permission': 'customer-mylist',
    'title': '我的客户',
    'url': '/customer/mine/list'
  }, {
    'permission': 'customer-public',
    'title': '客户公海',
    'url': '/customer/pools/list'
  }, {
    'permission': 'customer-shop-list',
    'title': '店铺列表',
    'url': '/customer/shop/list'
  }, {
    'permission': 'customer-shop-list',
    'title': '店铺跟进',
    'url': '/customer/shop/follow-records'
  }]
}]
