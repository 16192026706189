const sampleCode = "*"; // or @

export default {
  SAMPLE_CODE: sampleCode,

  CATE_VIEW: "@",
  CATE_OPERATE: "api-base.termTax::operate",

  QA_VIEW: "@",
  QA_OPERATE: "api-base.post::operate",

  MESSAGE_VIEW: "*", //api-base.notice::msg::view
  MESSAGE_OPERATE: "api-base.notice::msg::operate",
  MESSAGE_TYPE: "api-base.notice::msg::type",

  CAIWU_INVOICE: "caiwu-travel",
  CAIWU_CHAILV: "caiwu-travel",
  CAIWU_KOUFU: "caiwu-koufu",
  CAIWU_DEVICE_OPTION: "caiwu-device-option",
  CAIWU_DEVICE_PRODUCT: "caiwu-device-product",
  CAIWU_DEVICE_ITEM: "caiwu-device-item",
  CAIWU_DD_PROJECT: "caiwu-dd-project",

  DY_AD_ACCESS_TOKEN_LIST: "dy-ad-access-token-list",
  DY_AD_AUTH_ACCOUNT_LIST: "dy-ad-auth-account-list",
};
