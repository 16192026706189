import request from '../utils/request'
import { V1 as v1Url } from './constant'

export function myInvoice (params) {
  return request({
    url: v1Url.CAIWU_INVOICE_MY,
    method: 'GET',
    params: params
  })
}

export function listInvoice (params) {
  return request({
    url: v1Url.CAIWU_INVOICE_LIST,
    method: 'GET',
    params: params
  })
}

export function batchConfirmInvoice (data) {
  return request({
    url: v1Url.CAIWU_INVOICE_BATCH_CONFIRM,
    method: 'POST',
    data: data
  })
}

export function addInvoice (data) {
  return request({
    url: v1Url.CAIWU_INVOICE_CRETEE,
    method: 'POST',
    data: data
  })
}

export function editInvoice (id, data) {
  return request({
    url: v1Url.CAIWU_INVOICE_UPDATE + id,
    method: 'PUT',
    data: data
  })
}

export function deleteInvoice (id) {
  return request({
    url: v1Url.CAIWU_INVOICE_DELETE + id,
    method: 'DELETE'
  })
}

export function listSalaryDetail (params) {
  return request({
    url: v1Url.CAIWU_SALARY_LIST,
    method: 'GET',
    params: params
  })
}

export function addSalaryDetail (data) {
  return request({
    url: v1Url.CAIWU_SALARY_CREATE,
    method: 'POST',
    data: data
  })
}

export function editSalaryDetail (id, data) {
  return request({
    url: v1Url.CAIWU_SALARY_UPDATE + id,
    method: 'PUT',
    data: data
  })
}

export function deleteSalaryDetail (id) {
  return request({
    url: v1Url.CAIWU_SALARY_DELETE + id,
    method: 'DELETE'
  })
}

export function listDingtalkProjectStat (params) {
  return request({
    url: '/caiwu/dingtalk-project',
    method: 'GET',
    params: params
  })
}

export function addDingtalkProjectStat (data) {
  return request({
    url: '/caiwu/dingtalk-project',
    method: 'POST',
    data: data
  })
}
