import permCode from '@/config/permCode'

export default [{
  hidden: true,
  permission: permCode.SAMPLE_CODE,
  title: 'SAMPLE',
  icon: 'el-icon-menu',
  url: '/sample/index',
  children: [{
    permission: permCode.SAMPLE_CODE,
    title: '列表',
    url: '/sample/index'
  }, {
    permission: permCode.SAMPLE_CODE,
    title: 'OSS',
    url: '/sample/oss'
  }, {
    permission: permCode.SAMPLE_CODE,
    title: 'Editor',
    url: '/sample/editor'
  }]
}, {
  hidden: true,
  permission: '*',
  title: '导航一',
  icon: 'el-icon-location',
  url: '/sample/1',
  children: [{
    permission: permCode.SAMPLE_CODE,
    title: '选项1',
    url: '/sample/1-1'
  }, {
    permission: permCode.SAMPLE_CODE,
    title: '选项2',
    url: '/sample/1-2'
  }, {
    permission: permCode.SAMPLE_CODE,
    title: '选项3',
    url: '/sample/1-3',
    children: [{
      permission: 'unknown',
      title: '选项1',
      url: '/sample/1-3-1'
    }]
  }, {
    hidden: true,
    permission: permCode.SAMPLE_CODE,
    title: '选项4',
    url: '/sample/1-4',
    children: [{
      permission: permCode.SAMPLE_CODE,
      title: '选项1',
      url: '/sample/1-4-1'
    }]
  }, {
    permission: permCode.SAMPLE_CODE,
    title: '选项5',
    url: '//www.baidu.com'
  }]
}, {
  hidden: true,
  permission: permCode.SAMPLE_CODE,
  title: '导航二',
  icon: 'el-icon-menu',
  url: '/sample/2'
}, {
  hidden: true,
  permission: permCode.SAMPLE_CODE,
  title: '导航三',
  icon: 'el-icon-document',
  url: '/sample/3'
}, {
  hidden: true,
  permission: permCode.SAMPLE_CODE,
  title: '导航四',
  icon: 'el-icon-setting',
  url: '/sample/4'
}]
