import cacheUtil from '@/utils/CacheUtil'

const state = {
  sidebar: {
    opened: !+cacheUtil.getCookie('sidebarStatus'),
    withoutAnimation: false
  },
  language: cacheUtil.getCookie('language') || 'en'
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    if (state.sidebar.opened) {
      cacheUtil.setCookie('sidebarStatus', 1)
    } else {
      cacheUtil.setCookie('sidebarStatus', 0)
    }
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    cacheUtil.setCookie('sidebarStatus', 1)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  SET_LANGUAGE: (state, language) => {
    state.language = language
    cacheUtil.setCookie('language', language)
  }
}

const actions = {
  toggleSideBar ({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar ({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  setLanguage ({ commit }, language) {
    commit('SET_LANGUAGE', language)
  }
}

export default {
  state,
  mutations,
  actions
}
