import * as customer from '../../api/customer'

// initial state
const state = {}

// getters
const getters = {}

// actions
const actions = {
  getCustomers ({ commit }, params) {
    return new Promise((resolve, reject) => {
      customer.list(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getMyCustomers ({ commit }, params) {
    return new Promise((resolve, reject) => {
      customer.mylist(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getPublicCustomers ({ commit }, params) {
    return new Promise((resolve, reject) => {
      customer.publicList(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getCustomer ({ commit }, params) {
    return new Promise((resolve, reject) => {
      customer.view(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  addCustomer ({ commit }, data) {
    return new Promise((resolve, reject) => {
      customer.add(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  editCustomer ({ commit }, data) {
    return new Promise((resolve, reject) => {
      customer.edit(data.id, data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  receiveCustomer ({ commit }, data) {
    return new Promise((resolve, reject) => {
      customer.receive(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  transferCustomer ({ commit }, data) {
    return new Promise((resolve, reject) => {
      customer.transfer(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getCustomerFollowRecords ({ commit }, params) {
    return new Promise((resolve, reject) => {
      customer.listFollowRecord(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  addCustomerFollowRecord ({ commit }, params) {
    return new Promise((resolve, reject) => {
      customer.addFollowRecord(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getCustomerShops ({ commit }, params) {
    return new Promise((resolve, reject) => {
      customer.listShop(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  suggestCustomerShops ({ commit }, params) {
    return new Promise((resolve, reject) => {
      customer.suggestShop(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  addCustomerShop ({ commit }, data) {
    return new Promise((resolve, reject) => {
      customer.addShop(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  editCustomerShop ({ commit }, data) {
    return new Promise((resolve, reject) => {
      customer.editShop(data.id, data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getCustomerShopLogs ({ commit }, params) {
    return new Promise((resolve, reject) => {
      customer.listShopLogs(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getCustomerShopFollowRecords ({ commit }, params) {
    return new Promise((resolve, reject) => {
      customer.listShopFollowRecord(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  addCustomerShopFollowRecord ({ commit }, params) {
    return new Promise((resolve, reject) => {
      customer.addShopFollowRecord(params).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  state,
  getters,
  actions
}
