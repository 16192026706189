
export function isStrongPassword(password) {
  if (!password) {
    return false;
  }

  return checkPasswordStrength(password) >= 4;
}

export function checkPasswordStrength(password) {
  if (!password) {
    return 0;
  }

  const minLength = 8;
  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[-_$%&@+!]/.test(password); // !@#$%^&*(),.?":{}|<>
  const isValidLength = password.length >= minLength;

  if (!isValidLength) {
    return 0;
  }

  const conditionsMet = [hasLowerCase, hasUpperCase, hasNumber, hasSpecialChar].filter(Boolean).length;

  // switch (true) {
  //   case conditionsMet === 0:
  //   case conditionsMet === 1:
  //     return {valid: false, message: 'Password is too weak'};
  //   case conditionsMet === 2:
  //     return {valid: true, strength: 'Weak', message: 'Weak password'};
  //   case conditionsMet === 3:
  //     return {valid: true, strength: 'Medium', message: 'Medium strength password'};
  //   default:
  //     return {valid: true, strength: 'Strong', message: 'Strong password'};
  // }
  return conditionsMet;
}
