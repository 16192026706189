<template>
  <!-- 表格虚拟滚动 -->
  <div>
    <el-table
      :key="Math.random()"
      :data="tableData"
      v-loading="loading"
      :height="maxHeight ? maxHeight : null"
      v-bind="$attrs"
      v-on="$listeners"
      :border="props.border"
      :row-style="getTableRowStyle"
      :cell-style="getTableCellStyle"
      :default-sort="defaultSort"
      :span-method="objectSpanMethod"
      :sort-change="handleSort"
      @selection-change="handleSelectionChange"
      style="width: 100%"
    >
      <TableColumn
        v-for="(item, index) in columns"
        :key="index + Date.now()"
        :col="item"
        :selectable="selectable"
      >
        <template v-for="slot in Object.keys($scopedSlots)" #[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </TableColumn>
    </el-table>
    <div class="block pull-right" v-if="paginationType != 3">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.page_no"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import TableColumn from './tableColumn.vue'
export default {
  name: 'MyTable',
  components: { TableColumn },
  props: {
    props: {
      // 表格参数
      type: Object,
      default: () => {
        return { border: true }
      }
    },
    // 表头数据
    columns: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 分页类型 默认后端分页  1后端分页 2前端分页 3不分页
    paginationType: {
      type: Number,
      default: () => {
        return 1
      }
    },
    // 默认排序规则
    defaultSort: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // Table最大高度
    maxHeight: {
      type: [String, Number]
    },
    isInit: {
      // 是否初始化
      type: Boolean,
      default: () => {
        return true
      }
    },
    dataList: {
      // 直接由父组件传递列表数据 不需要组件内部查询数据
      type: Array,
      default: () => {
        return []
      }
    },
    getParams: Function, // 查询参数
    method: Function, // 查询方法
    selectable: Function, // 多选禁用
    handleSelectionChange: {
      // 直接父组件传递列表数据
      type: Function,
      default: () => {
        return () => {}
      }
    }, // 多选 表格选中行发生变化时触发的事件
    objectSpanMethod: Function, // 表格单元格合并方法
    handleSort: Function, // 排序方法
    getTableRowStyle: Function, // 表格行背景颜色获取方法
    getTableCellStyle: Function // 表格单元格背景颜色获取方法
  },
  data() {
    return {
      tableData: [], // 列表数据
      allTableData: [], // 所有数据 前端分页时存储当前查询条件下查询除的所有数据 用于切换页数时截取列表展示内容
      pagination: {
        // 分页参数
        page_no: 1,
        page_size: 10,
        total: 0,
        pageSizes: [10, 20, 30, 40, 50]
      },
      loading: false
    }
  },
  mounted() {
    if (this.isInit) {
      this.search()
    }
  },
  watch: {
    dataList: {
      // 不需要组件内部查询 直接从外部传递列表数据
      immediate: true,
      handler(val) {
        if (val) {
          if (this.paginationType === 3) {
            // 不分页
            this.tableData = val
          } else {
            // 分页
            this.allTableData = val
            this.getTableData()
          }
        }
      }
    }
  },
  methods: {
    search() {
      // 查询按钮调用
      this.pagination.page_no = 1
      this.getItemList()
    },

    getItemList() {
      // 查询数据
      this.handleSelectionChange([])
      this.loading = true
      let params = {
        ...this.getParams(),
        page_no: this.pagination.page_no,
        page_size: this.pagination.page_size
      }
      this.method(params)
        .then((res) => {
          this.tableData = res?.data?.records || []
          this.pagination.total = res?.data?.totalCount || 0
          this.$emit('results', res?.data)
        })
        .finally(() => {
          this.loading = false
        })
        .catch((error) => {
          this.tableData = []
          this.loading = false
          this.$message.error('查询失败')
          console.error(error)
        })
    },

    getTableData() {
      // 前端分页时 点击分页器 无须再次查询 从allTableData中截取数据即可
      this.tableData = this.allTableData.slice(
        (this.pagination.page_no - 1) * this.pagination.page_size,
        this.pagination.page_no * this.pagination.page_size - 1
      )
    },

    handleSizeChange(val) {
      // 每页显示条数切换
      this.pagination.page_no = 1
      this.pagination.page_size = val
      if (this.paginationType === 2) {
        // 前端分页 直接截取
        this.getTableData()
      } else {
        // 后端分页 调用接口查询
        this.getItemList()
      }
    },

    handleCurrentChange(val) {
      // 页数切换
      this.pagination.page_no = val
      if (this.paginationType === 2) {
        // 前端分页 直接截取
        this.getTableData()
      } else {
        // 后端分页 调用接口查询
        this.getItemList()
      }
    }
  }
}
</script>
