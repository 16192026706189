import request from "../utils/request";
import { BASE as baseUrl } from "./constant";

export function addEventTracking(data) {
  return request({
    url: baseUrl.QA_EVENT_TRACKING_ADD,
    method: "POST",
    data: data,
  });
}

export function qaHotSearchWordList(params) {
  return request({
    url: baseUrl.QA_HOT_SEARCH_WORD_LIST,
    method: "GET",
    params: params,
  });
}

export function termGet(params) {
  return request({
    url: baseUrl.TERM_LIST,
    method: "GET",
    params: params,
  });
}
export function termTaxonomyList(params) {
  return request({
    url: baseUrl.TERM_TAXONOMY_LIST,
    method: "GET",
    params: params,
  });
}
export function termTaxonomyTree(params) {
  return request({
    url: baseUrl.TERM_TAXONOMY_TREE,
    method: "GET",
    params: params,
  });
}
export function termTaxonomyCreate(data) {
  return request({
    url: baseUrl.TERM_TAXONOMY_CREATE,
    method: "POST",
    data: data,
  });
}
export function termTaxonomyUpdate(data) {
  return request({
    url: baseUrl.TERM_TAXONOMY_UPDATE,
    method: "POST",
    data: data,
  });
}
export function termTaxonomyDelete(data) {
  return request({
    url: baseUrl.TERM_TAXONOMY_DELETE,
    method: "POST",
    data: data,
  });
}

export function postList(data) {
  return request({
    url: baseUrl.POST_LIST,
    method: "POST",
    data: data,
  });
}
export function postCreate(data) {
  return request({
    url: baseUrl.POST_CREATE,
    method: "POST",
    data: data,
  });
}
export function postUpdate(data) {
  return request({
    url: baseUrl.POST_UPDATE,
    method: "POST",
    data: data,
  });
}
export function postDelete(data) {
  return request({
    url: baseUrl.POST_DELETE,
    method: "POST",
    data: data,
  });
}
export function postImport(data) {
  return request({
    url: baseUrl.POST_QA_IMPORT,
    method: "POST",
    data: data,
  });
}
