import request from '../utils/request'
import { V1 as v1Url } from './constant'

export function lock (id) {
  return request({
    url: v1Url.ADMIN_LOCK + id,
    method: 'PUT'
  })
}

export function unlock (id) {
  return request({
    url: v1Url.ADMIN_UNLOCK + id,
    method: 'PUT'
  })
}

export function list (params) {
  return request({
    url: v1Url.ADMIN_LIST,
    method: 'GET',
    params: params
  })
}

export function suggest (params) {
  return request({
    url: v1Url.ADMIN_SUGGEST,
    method: 'GET',
    params: params
  })
}

export function add (data) {
  return request({
    url: v1Url.ADMIN_CREATE,
    method: 'POST',
    data: data
  })
}

export function edit (id, data) {
  return request({
    url: v1Url.ADMIN_UPDATE + id,
    method: 'PUT',
    data: data
  })
}

export function treeDept (params) {
  return request({
    url: v1Url.ADMIN_DEPT_TREE,
    method: 'GET',
    params: params
  })
}

export function listDept (params) {
  return request({
    url: v1Url.ADMIN_DEPT_LIST,
    method: 'GET',
    params: params
  })
}

export function addDept (data) {
  return request({
    url: v1Url.ADMIN_DEPT_CREATE,
    method: 'POST',
    data: data
  })
}

export function editDept (id, data) {
  return request({
    url: v1Url.ADMIN_DEPT_UPDATE + id,
    method: 'PUT',
    data: data
  })
}

export function listGroup (params) {
  return request({
    url: v1Url.ADMIN_GROUP_LIST,
    method: 'GET',
    params: params
  })
}

export function addGroup (data) {
  return request({
    url: v1Url.ADMIN_GROUP_CREATE,
    method: 'POST',
    data: data
  })
}

export function editGroup (id, data) {
  return request({
    url: v1Url.ADMIN_GROUP_UPDATE + id,
    method: 'PUT',
    data: data
  })
}

export function listPermissions (params) {
  return request({
    url: v1Url.ADMIN_PERMISSION_LIST,
    method: 'GET',
    params: params
  })
}

export function addPermission (data) {
  return request({
    url: v1Url.ADMIN_PERMISSION_CREATE,
    method: 'POST',
    data: data
  })
}

export function listRoles (params) {
  return request({
    url: v1Url.ADMIN_ROLE_LIST,
    method: 'GET',
    params: params
  })
}

export function addRole (data) {
  return request({
    url: v1Url.ADMIN_ROLE_CREATE,
    method: 'POST',
    data: data
  })
}

export function getRole (params) {
  return request({
    url: v1Url.ADMIN_ROLE_GET,
    method: 'GET',
    params: params
  })
}

export function getAuth (params) {
  return request({
    url: v1Url.ADMIN_AUTH,
    method: 'GET',
    params: params
  })
}

export function assignAuth (data) {
  return request({
    url: v1Url.ADMIN_AUTH_ASSIGN,
    method: 'POST',
    data: data
  })
}

export function listAssianData (params) {
  return request({
    url: v1Url.ADMIN_AUTH_DATA_LIST,
    method: 'GET',
    params: params
  })
}

export function assignData (data) {
  return request({
    url: v1Url.ADMIN_AUTH_DATA_ASSIGN,
    method: 'POST',
    data: data
  })
}

export function revokeData (data) {
  return request({
    url: v1Url.ADMIN_AUTH_DATA_REVOKE,
    method: 'POST',
    data: data
  })
}

export function treeMenu (params) {
  return request({
    url: v1Url.ADMIN_MENU_TREE,
    method: 'GET',
    params: params
  })
}

export function listMenu (params) {
  return request({
    url: v1Url.ADMIN_MENU_LIST,
    method: 'GET',
    params: params
  })
}

export function addMenu (data) {
  return request({
    url: v1Url.ADMIN_MENU_CREATE,
    method: 'POST',
    data: data
  })
}

export function editMenu (id, data) {
  return request({
    url: v1Url.ADMIN_MENU_UPDATE + id,
    method: 'PUT',
    data: data
  })
}

export function deleteMenu (id, data) {
  return request({
    url: v1Url.ADMIN_MENU_DELETE + id,
    method: 'DELETE',
    data: data
  })
}
