import router from "@/router";
import store from "@/store";
import { hasPermission, hasToken } from "./utils/AuthUtil";
import { Message } from "element-ui";

let whiteList = [process.env.VUE_APP_URL_LOGIN, "/message/detail"];

router.beforeEach((to, from, next) => {
  if (whiteList.indexOf(to.path) > -1) {
    return next();
  }
  // console.log('========from: ', from)
  // console.log('========to: ', to)
  // 判断该路由是否需要登录权限 todo 对于外链 //www.xxx.com https://www.xxx.com 应该直接通过
  let _allowed = false;
  if (to.meta !== undefined && to.meta.permission !== undefined) {
    if (!store.getters.accessToken) {
      store.dispatch("refreshUserInfo");
      let _key = process.env.VUE_APP_CACHE_PERMISSION;
      if (sessionStorage.getItem(_key)) {
        store.dispatch(
          "setPermission",
          JSON.parse(sessionStorage.getItem(_key))
        );
        sessionStorage.removeItem(_key);
      }
      // if (store.getters.accessToken !== '' && store.getters.permissions.length === 0) {
      //   store.dispatch('fetchPermission')
      // }
      // console.log('=======refreshUserInfo store.accessToken: ', store.getters.accessToken)
    }
    _allowed = hasPermission(to.meta.permission, store.getters.permissions);
  }
  // console.log('=============_allowed', _allowed, to.meta.permission, store.getters.permissions, to)
  if (_allowed) {
    return next();
  } else if (!hasToken()) {
    if (location.href.indexOf(process.env.VUE_APP_URL_LOGIN) !== -1) {
      return next();
    }
    next({
      path: process.env.VUE_APP_URL_LOGIN,
      // , query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
    });
  } else {
    // Element.Message.error('无权该操作！')
    // console.log(to)
    Message({
      message: "无权该操作",
      type: "error",
      duration: 3 * 1000,
    });
    next({
      path: "/",
    });
  }
});
