export default [{
  'permission': 'xuqiu-list',
  'title': '需求管理',
  sortOrder: 40,
  'icon': 'el-icon-menu',
  'url': '#xuqiu',
  'children': [{
    'permission': 'xuqiu-type-list',
    'title': '需求类型',
    'url': '/xuqiu/type'
  }, {
    'permission': 'xuqiu-list',
    'title': '提需求',
    'url': '/xuqiu/publish'
  }, {
    'permission': 'xuqiu-list',
    'title': '待我处理',
    'url': '/xuqiu/wait-handle'
  }, {
    'permission': 'xuqiu-list',
    'title': '我发起的',
    'url': '/xuqiu/my-publish'
  }, {
    'permission': 'xuqiu-list',
    'title': '我处理的',
    'url': '/xuqiu/my-handle'
  }, {
    'permission': 'xuqiu-list',
    'title': '需求列表',
    'url': '/xuqiu/list'
  }]
}]
