import * as admin from "../../api/admin";

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {
  getAdmins({ commit }, params) {
    return new Promise((resolve, reject) => {
      admin
        .list(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  suggestAdmins({ commit }, params) {
    return new Promise((resolve, reject) => {
      admin
        .suggest(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      admin
        .add(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  editAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      admin
        .edit(data.id, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  lockAdmin({ commit }, id) {
    return new Promise((resolve, reject) => {
      admin
        .lock(id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  unlockAdmin({ commit }, id) {
    return new Promise((resolve, reject) => {
      admin
        .unlock(id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAdminDeptTree({ commit }, params) {
    return new Promise((resolve, reject) => {
      admin
        .treeDept(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAdminDepts({ commit }, params) {
    return new Promise((resolve, reject) => {
      admin
        .listDept(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addAdminDept({ commit }, data) {
    return new Promise((resolve, reject) => {
      admin
        .addDept(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  editAdminDept({ commit }, data) {
    return new Promise((resolve, reject) => {
      admin
        .editDept(data.id, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAdminGroups({ commit }, params) {
    return new Promise((resolve, reject) => {
      admin
        .listGroup(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addAdminGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      admin
        .addGroup(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  editAdminGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      admin
        .editGroup(data.id, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAdminPermissions({ commit }, params) {
    return new Promise((resolve, reject) => {
      admin
        .listPermissions(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addAdminPermission({ commit }, data) {
    return new Promise((resolve, reject) => {
      admin
        .addPermission(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAdminRoles({ commit }, params) {
    return new Promise((resolve, reject) => {
      admin
        .listRoles(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addAdminRole({ commit }, data) {
    return new Promise((resolve, reject) => {
      admin
        .addRole(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAdminRole({ commit }, params) {
    return new Promise((resolve, reject) => {
      admin
        .getRole(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAdminAuth({ commit }, params) {
    return new Promise((resolve, reject) => {
      admin
        .getAuth(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  assignAdminAuth({ commit }, data) {
    return new Promise((resolve, reject) => {
      admin
        .assignAuth(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  listAssignData({ commit }, params) {
    return new Promise((resolve, reject) => {
      admin
        .listAssianData(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  assignAdminData({ commit }, data) {
    return new Promise((resolve, reject) => {
      admin
        .assignData(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  revokeAdminData({ commit }, data) {
    return new Promise((resolve, reject) => {
      admin
        .revokeData(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAdminMenuTree({ commit }, params) {
    return new Promise((resolve, reject) => {
      admin
        .treeMenu(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAdminMenus({ commit }, params) {
    return new Promise((resolve, reject) => {
      admin
        .listMenu(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addAdminMenu({ commit }, data) {
    return new Promise((resolve, reject) => {
      admin
        .addMenu(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  editAdminMenu({ commit }, data) {
    return new Promise((resolve, reject) => {
      admin
        .editMenu(data.id, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteAdminMenu({ commit }, data) {
    return new Promise((resolve, reject) => {
      admin
        .deleteMenu(data.id, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
};
