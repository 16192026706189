import Layout from '@/views/layout/index'

const xuqiuRouter = {
  path: '/xuqiu',
  component: Layout,
  hidden: true,
  meta: {
    permission: 'xuqiu-list'
  },
  children: [{
    path: 'type',
    meta: {
      permission: 'xuqiu-type-list'
    },
    component: () => import('@/views/xuqiu/type')
  }, {
    path: 'detail/:id',
    name: 'xuqiu',
    meta: {
      permission: 'xuqiu-list'
    },
    component: () => import('@/views/xuqiu/detail')
  }, {
    path: 'publish',
    meta: {
      permission: 'xuqiu-list'
    },
    component: () => import('@/views/xuqiu/publish')
  }, {
    path: 'list',
    meta: {
      permission: 'xuqiu-list'
    },
    component: () => import('@/views/xuqiu/list')
  }, {
    path: 'wait-handle',
    meta: {
      permission: 'xuqiu-list'
    },
    component: () => import('@/views/xuqiu/wait-handle')
  }, {
    path: 'my-publish',
    meta: {
      permission: 'xuqiu-list'
    },
    component: () => import('@/views/xuqiu/my-publish')
  }, {
    path: 'my-handle',
    meta: {
      permission: 'xuqiu-list'
    },
    component: () => import('@/views/xuqiu/my-handle')
  }]
}

export default xuqiuRouter
