export default [{
  'permission': ['admin-list', 'admin-group-list', 'admin-menu-list'],
  'title': '管理员',
  sortOrder: 20,
  'icon': 'el-icon-menu',
  'url': '#admin',
  'children': [{
    'permission': 'admin-list',
    'title': '列表',
    'url': '/admin/list'
  }, {
    'permission': 'admin-dept-list',
    'title': '部门',
    'url': '/admin/dept/list'
  }, {
    'permission': 'admin-group-list',
    'title': '分组',
    'url': '/admin/group/list'
  }, {
    'permission': 'admin-create-list',
    'title': '权限',
    'url': '/admin/permission/list'
  }, {
    'permission': 'admin-create-list',
    'title': '角色',
    'url': '/admin/role/list'
  }, {
    'permission': 'admin-menu-list',
    'title': '菜单',
    'url': '/admin/menu/list'
  }]
}]
